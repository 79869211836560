import {DiagramData, FlowNodeDataSerialized} from '../types';
import { partClassDefDict } from '../partClasses/allPartClassDefs';

export function ValidateAnalysisData(diagramData:DiagramData) {
    const diagramErrorMessages = [];
    const diagramIDs = [];
    for (const element of diagramData.flowData.elements) {
        if ('source' in element) continue;
        const node = element;
        const nodeData = node.data as FlowNodeDataSerialized;
        if (!nodeData) throw new Error(`Node ${node.id} has has no data.`);
        const partClassDef = partClassDefDict[nodeData.partClass];
        if (!nodeData.partID && !partClassDef.hasNoPart) {
        diagramErrorMessages.push(`Node ${nodeData.diagramID} must be assigned a part ID.`);
        }
        diagramIDs.push(nodeData.diagramID.toUpperCase());
    }
    for (const element of diagramData.flowData.elements) {
        if ('source' in element) continue;
        const nodeData = element.data as FlowNodeDataSerialized;
        const ID  = nodeData.diagramID.toUpperCase();
        const duplicates = diagramIDs.filter(x => x === ID).length > 1;
        if (duplicates) diagramErrorMessages.push(`Multiple nodes uses Diagram ID ${ID}. Diagram ID's must be Unique.`);
        if (ID === '') diagramErrorMessages.push(`Diagram IDs Cannot Be Blank!`);
    }
    return diagramErrorMessages;
}