import React, {useEffect, useState, useRef, useCallback} from 'react';
import * as HeroIcons from '@heroicons/react/outline';
import {classNames} from '../../utils/classNames';
import DesignTab, {DesignTabInitialState} from './DesignTab';
import AnalysisTab, {AnalysisDataContainer} from './AnalysisTab';
import ErrorHandlerContainer from '../../components/ErrorHandlerContainer';
import AuthContainer, {AuthUser} from '../../components/AuthContainer';
import {useLocation, useNavigate} from 'react-router-dom';
import config from '../../config';
import {Diagram, DiagramData, FlowInstance, FlowNodeDataSerialized, Part, PartSerialized, RemoteDiagram, StashedDiagram} from '../../types';
import {partClassDefDict} from '../../partClasses/allPartClassDefs';
import DiagramLoadModal from './DiagramLoadModal';
import {compareDiagrams, fetchDiagram, loadStashedDiagram, saveDiagramRemote, saveStashedDiagram, serializeDiagramData, clearStashedDiagram} from '../../utils/diagramHelper';
import DownloadsButton from '../../components/DownloadModal';
import ShareModal from './ShareModal';
import InfoModel from './InfoModal';
const DEFAULT_DIAGRAM_TITLE = 'Untitled';
const BLANK_DIAGRAM_ID = '_BLANK';

export default function EditDiagramPage() {
  const [diagramTitle, setDiagramTitle] = useState(DEFAULT_DIAGRAM_TITLE);
  const [isLoadingStashedDiagram, setIsLoadingStashedDiagram] = useState(true);
  const [isLoadingParts, setIsLoadingParts] = useState(false);
  const [isLoadingRequestedDiagram, setIsLoadingRequestedDiagram] = useState(false);
  const [loadingPartsErrorMessage, setLoadingPartsErrorMessage] = useState<string>();
  const [loadingRequestedDiagramErrorMessage, setLoadingRequestedDiagramErrorMessage] = useState<string>();
  const [showDiagramLoadModal, setShowDiagramLoadModal] = useState(false);
  const [parts, setParts] = useState<Part[]>();
  const diagramSateRef = useRef<{
    isReady?: boolean;
    stashedDiagram?: StashedDiagram;
    remoteDiagram?: RemoteDiagram;
    title: string;
    getDiagramData?: () => DiagramData | undefined;
  }>({
    title: diagramTitle
  });
  const [status, setStatus] = useState<string>('');
  const [requestedDiagram, setRequestedDiagram] = useState<Diagram | null>();
  const [remoteDiagram, setRemoteDiagram] = useState<RemoteDiagram>();
  const [designTabInitialState, setDesignTabInitialState] = useState<DesignTabInitialState>({});
  const [infoState, setInfoState] = useState<boolean>(false);
  const [flowInst, setFlowInst] = useState<FlowInstance>();
  const {authUser} = AuthContainer.useContainer();
  const {handleError} = ErrorHandlerContainer.useContainer();
  const location = useLocation();
  const navigate = useNavigate();
  const getDiagramData = useCallback(() => {
    if (!flowInst) return;
    return serializeDiagramData(flowInst.toObject());
  }, [flowInst]);
  
  useEffect(() => {
    diagramSateRef.current.isReady = !isLoadingStashedDiagram;
  }, [isLoadingStashedDiagram]);
  useEffect(() => {
    diagramSateRef.current.remoteDiagram = remoteDiagram;
  }, [remoteDiagram]);
  useEffect(() => {
    diagramSateRef.current.title = diagramTitle;
  }, [diagramTitle]);
  useEffect(() => {
    diagramSateRef.current.getDiagramData = getDiagramData;
  }, [getDiagramData]);
  
  // Load stashed diagram.
  useEffect(() => {
    console.log('Loading stashed diagram...');
    const stashedDiagram = loadStashedDiagram();
    diagramSateRef.current.stashedDiagram = stashedDiagram;
    setRemoteDiagram(stashedDiagram?.remoteConnection? {
      id: stashedDiagram.remoteConnection.diagramID,
      ownerUserID: stashedDiagram.remoteConnection.diagramOwnerUserID,
      lastSeenDiagram: stashedDiagram.isDirty? undefined : {
        title: stashedDiagram.title,
        data: stashedDiagram.data,
      }
    } : undefined);
    setDiagramTitle(stashedDiagram?.title ?? DEFAULT_DIAGRAM_TITLE);
    setDesignTabInitialState({diagramData: stashedDiagram?.data});
    setIsLoadingStashedDiagram(false);
    console.log(`Loaded stashed diagram.`, {id: stashedDiagram?.remoteConnection?.diagramID, title: stashedDiagram?.title, isDirty: stashedDiagram?.isDirty});
  }, []);
  
  // Load parts.
  useEffect(() => {
    if (parts || isLoadingParts) return;
    
    console.log('Loading parts...');
    setIsLoadingParts(true);
    setLoadingPartsErrorMessage(undefined);
    
    (async () => {
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      
      const partsRes = await fetch(`${config.apiURL}/getParts?catalogSlug=proteus`);
      if (!partsRes.ok) {
        const bodyStr = await partsRes.text();
        throw new Error(`Non-2XX response when fetching parts: ${partsRes.status}\n${bodyStr}`);
      }
      
      const apiParts: PartSerialized[] = (await partsRes.json()).parts;
      const cParts: Part[] = [];
      for (const apiPart of apiParts) {
        const partClassDef = partClassDefDict[apiPart.partClass];
        if (!partClassDef) {
          console.warn(`Unknown part class '${apiPart.partClass}' on part ${apiPart.id}.`);
          continue;
        }
        cParts.push({
          ...apiPart,
          partClassDef
        });
      }
      setParts(cParts);
      console.log(`Loaded ${cParts.length} parts.`);
    })()
    .catch(err => {
      setLoadingPartsErrorMessage('An error occured.');
      handleError(err);
    })
    .finally(() => setIsLoadingParts(false));
  }, []);
  
  // Load requested diagram.
  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    const diagramID = qs.get('diagramID');
    if (!diagramID) return;
    
    qs.delete('diagramID');
    navigate({
      pathname: location.pathname,
      hash: location.hash,
      search: qs.toString(),
    }, {replace: true});
    
    console.log(`Loading requested diagram '${diagramID}'...`);
    setIsLoadingRequestedDiagram(true);
    setLoadingRequestedDiagramErrorMessage(undefined);
    setShowDiagramLoadModal(true);
    
    (async () => {
      let diagram: Diagram | null;
      
      if (diagramID === BLANK_DIAGRAM_ID) {
        diagram = null;
        console.log(`Requested blank diagram.`);
      }
      else {
        const fetchedDiagram = await fetchDiagram(diagramID, authUser);
        
        if (!fetchedDiagram) {
          setLoadingRequestedDiagramErrorMessage('Diagram not found.');
          return;
        }
        
        diagram = fetchedDiagram;
        console.log(`Loaded requested diagram '${diagramID}'.`);
      }
      
      setRequestedDiagram(diagram);
      
      stashCurrentDiagram();
      if (diagramSateRef.current.stashedDiagram?.isDirty) {
        setIsLoadingRequestedDiagram(false);
        // Dialog will now show confirm override changes confirmation.
      }
      else {
        setShowDiagramLoadModal(false);
        loadDiagramIntoEditor(diagram);
      }
    })()
    .catch(err => {
      setIsLoadingRequestedDiagram(false);
      setLoadingRequestedDiagramErrorMessage('An error occured.');
      handleError(err);
    });
  }, [location.search]);
  
  // Start auto-stashing diagram.
  useEffect(() => {
    const interval = setInterval(() => {
      stashCurrentDiagram();
    }, 5000);
    
    const beforeUnloadEventListener = (event: WindowEventMap['beforeunload']) => {
      stashCurrentDiagram();
      delete event.returnValue;
    };
    window.addEventListener('beforeunload', beforeUnloadEventListener);
    
    return () => {
      stashCurrentDiagram();
      clearInterval(interval);
      window.removeEventListener('beforeunload', beforeUnloadEventListener);
    };
  }, []);
  
  /*
  function startNewDiagram(stashedDiagram: StashedDiagram | undefined){
    if (!stashedDiagram) {
      setLoadingRequestedDiagramErrorMessage('Diagram not found.');
      return;
    }
    const diagram = {
      id: uuid.v4(),
      title: 'Untitled',
      data: stashedDiagram.data,
      updatedTSStr: '',
    } as Diagram;
    setRequestedDiagram(diagram);

    setIsLoadingRequestedDiagram(true);
    setLoadingRequestedDiagramErrorMessage(undefined);
    setShowDiagramLoadModal(true);

    console.log('Loading new diagram...');
    if (diagramSateRef.current.stashedDiagram?.isDirty) {
      setIsLoadingRequestedDiagram(false);
      // Dialog will now show confirm override changes confirmation.
    }
    else {  
      setShowDiagramLoadModal(false);
      }
  }
  */

  function loadDiagramIntoEditor(diagram: Diagram | null) {
    console.log(`Loading ${diagram? `diagram '${diagram.id}'` : 'blank diagram'} into editor...`);
    
    setDiagramTitle(diagram?.title ?? DEFAULT_DIAGRAM_TITLE);
    setDesignTabInitialState({diagramData: diagram?.data});
    setRemoteDiagram(diagram? {
      id: diagram.id,
      ownerUserID: diagram.ownerUserID,
      lastSeenDiagram: {
        title: diagram.title || '',
        data: diagram.data,
      }
    } : undefined);
    
    if (diagram) {
      stashDiagram({
        remoteConnection: {
          diagramID: diagram.id,
          diagramOwnerUserID: diagram.ownerUserID,
        },
        title: diagram.title || '',
        data: diagram.data,
        isDirty: false,
      });
    }
    else {
      unstashDiagram();
    }
    
    console.log(`Loaded ${diagram? `diagram '${diagram.id}'` : 'blank diagram'} into editor.`);
  }
  
  async function saveDiagram(authUser: AuthUser) {
    const diagramData = getDiagramData();
    if (!diagramData) return;
    
    const oldRemoteDiagram = diagramSateRef.current.remoteDiagram;
    
    const diagramDef = {
      id: oldRemoteDiagram?.id,
      title: diagramTitle,
      data: diagramData
    };
    setStatus('Saving diagram');
    console.log(`Saving diagram...`, {id: diagramDef.id});
    const diagramID = await saveDiagramRemote(authUser, diagramDef);
    const newRemoteDiagram: RemoteDiagram = {
      id: diagramID,
      ownerUserID: oldRemoteDiagram?.ownerUserID ?? authUser.id, // TODO: Assuming the authorized user is the owner.
      lastSeenDiagram: {
        title: diagramDef.title,
        data: diagramDef.data,
      }
    };
    
    setRemoteDiagram(newRemoteDiagram);
    stashCurrentDiagram();
    setStatus('Saved diagram');
    console.log(`Saved diagram '${diagramID}'.`);
  }
  
  function stashDiagram(stashedDiagram: StashedDiagram) {
    if (!diagramSateRef.current.isReady) return;
    setStatus(`Stashing Unsaved Changes...`);
    console.log(`Stashing diagram...`);
    
    saveStashedDiagram(stashedDiagram);
    diagramSateRef.current.stashedDiagram = stashedDiagram;
    setStatus(`Stashed! Save diagram to preserve changes`);
    console.log(`Stashed diagram.`, {id: stashedDiagram?.remoteConnection?.diagramID, title: stashedDiagram.title, isDirty: stashedDiagram.isDirty});
  }
  function stashCurrentDiagram() {
    const diagramData = diagramSateRef.current.getDiagramData?.();
    if (!diagramData) return;
    
    const oldStashedDiagram = diagramSateRef.current.stashedDiagram;
    const remoteDiagram = diagramSateRef.current.remoteDiagram;
    
    // Check if we have the default diagram.
    const isDefaultDiagram = (
      !oldStashedDiagram?.remoteConnection &&
      (
        diagramData.flowData.elements.length === 0 || (
          diagramData.flowData.elements.length === 2 &&
          (diagramData.flowData.elements[0].data as FlowNodeDataSerialized)?.partClass === 'Source' &&
          (diagramData.flowData.elements[1].data as FlowNodeDataSerialized)?.partClass === 'Load'
        )
      )
    );
    
    const newStashedDiagram: StashedDiagram = {
      remoteConnection: remoteDiagram && {
        diagramID: remoteDiagram.id,
        diagramOwnerUserID: remoteDiagram.ownerUserID,
      },
      title: diagramSateRef.current.title,
      data: diagramData,
      isDirty: false // updated bellow
    };
    newStashedDiagram.isDirty = (
      remoteDiagram?.lastSeenDiagram
      ? !compareDiagrams(newStashedDiagram, remoteDiagram.lastSeenDiagram)
      : !isDefaultDiagram
    );
    
    // Check if the diagram has changed from last stash.
    if (
      !oldStashedDiagram ||
      oldStashedDiagram.isDirty !== newStashedDiagram.isDirty ||
      !compareDiagrams(oldStashedDiagram, newStashedDiagram)
    ) {
      stashDiagram(newStashedDiagram);
    }
  }
  function unstashDiagram() {
    console.log(`Clearing stashed diagram...`);
    clearStashedDiagram();
    diagramSateRef.current.stashedDiagram = undefined;
    console.log(`Cleared stashed diagram.`);
  }
  
  
  
  return (<>
    <DiagramLoadModal
      show={showDiagramLoadModal}
      isLoading={isLoadingRequestedDiagram}
      errorMessage={loadingRequestedDiagramErrorMessage}
      onClose={() => {
        if (!isLoadingRequestedDiagram) {
          setShowDiagramLoadModal(false);
        }
      }}
      onConfirm={() => {
        if (requestedDiagram !== undefined) {
          loadDiagramIntoEditor(requestedDiagram);
          setShowDiagramLoadModal(false);
        }
      }}
    />
    <div className="h-full flex flex-col">
      {isLoadingStashedDiagram || isLoadingParts? (
        <p>
          Loading...
          <svg className="animate-spin ml-2 -mr-1 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </p>
      ) : loadingPartsErrorMessage? (
        <p className="text-red-600">{loadingPartsErrorMessage}</p>
      ) : (<>
        <header className="flex-none">
          <TitleEditor
            title={diagramTitle}
            onTitleChange={title => setDiagramTitle(title)}
          />
        </header>
        <main className="flex-1 pt-4">
          <TabBar
            parts={parts || []}
            remoteDiagram={remoteDiagram}
            designTabInitialState={designTabInitialState}
            flowInst={flowInst}
            diagramTitle ={diagramTitle}
            status = {status}
            infoState = {infoState}
            setFlowInst={setFlowInst}
            getDiagramData={getDiagramData}
            saveDiagram={saveDiagram}
            setInfoState={setInfoState}
          />
        </main>
      </>)}
    </div>
  </>);
}

function TitleEditor({title, onTitleChange}: {
  title: string;
  onTitleChange: (title: string) => void;
}) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const titleInputRef = React.useRef<HTMLInputElement>(null);
  
  function startEdit() {
    if (!isEditingTitle) {
      setIsEditingTitle(true);
    }
  }
  function finishEdit() {
    if (titleInputRef.current) {
      onTitleChange(titleInputRef.current.value);
    }
    setIsEditingTitle(false);
  }
  
  return (
    isEditingTitle? (
      <form className="w-full" onSubmit={event => {finishEdit(); event.preventDefault(); return false;}}>
        <input ref={titleInputRef} autoFocus defaultValue={title} onBlur={() => finishEdit()} className="text-2xl sm:text-3xl outline-none border-none bg-gray-200 w-full" />
      </form>
    ) : (
      <div className="flex">
        <div className="group flex overflow-hidden" onClick={() => startEdit()}>
          <div className="flex-initial overflow-hidden">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl truncate">{title}</h2>
          </div>
          <div>
            <button className="ml-1 invisible group-hover:visible">
              <HeroIcons.PencilAltIcon className="sm:h-5 sm:w-5 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

function TabBar({parts, remoteDiagram, designTabInitialState, flowInst,diagramTitle,status,infoState, setFlowInst, getDiagramData, saveDiagram,setInfoState}: {
  parts: Part[];
  remoteDiagram?: RemoteDiagram;
  designTabInitialState: DesignTabInitialState;
  flowInst?: FlowInstance;
  diagramTitle: string;
  status: string;
  infoState: boolean;
  setFlowInst: (flowInst: FlowInstance) => void;
  getDiagramData: () => DiagramData | undefined;
  saveDiagram: (authUser: AuthUser) => Promise<void>;
  setInfoState: (infoBoolean: boolean) => void;
}) {
  const tabs = [
    {slug: 'design', title: 'Design'},
    {slug: 'analysis', title: 'Analysis'},
    //{slug: 'worstCaseAnalysis', title: 'Worst Case Analysis'},
    // {slug: 'DFM', title: 'Manufacturing Details'}
  ];
  const [currentTabSlug, setCurrentTabSlug] = useState(tabs[0].slug);
  const [showShareModal, setShowShareModal] = useState(false);
  
  return (<>
    <ShareModal
      diagramID={remoteDiagram?.id || 'error'}
      show={showShareModal}
      onClose={() => setShowShareModal(false)}
    />
    <div className="flex flex-col h-full">
      <AnalysisDataContainer.Provider>
        <div className="flex-none flex justify-center">
            <nav className="flex-initial overflow-hidden flex" aria-label="Tabs">
              {tabs.map((tab, tabIdx) => (
                <button
                  key={tab.slug}
                  className={classNames(
                    tab.slug === currentTabSlug? 'text-gray-900 bg-white border-b-0' : 'text-gray-500 hover:text-gray-700 bg-gray-100 hover:bg-gray-200 border-b',
                    tabIdx === 0 ? 'rounded-tl-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-tr-lg border-r' : '',
                    'flex-initial overflow-hidden overflow-ellipsis whitespace-nowrap py-4 px-4 text-sm font-medium text-center outline-none border-t border-l border-gray-200'
                  )}
                  aria-current={tab.slug === currentTabSlug ? 'page' : undefined}
                  onClick={() => setCurrentTabSlug(tab.slug)}
                >
                  {tab.title}
                </button>
              ))}
            </nav>
            <div className="flex-1 border-b flex justify-end items-center">
              <StatusText status = {status}/>
              <InfoButton setInfoState= {setInfoState}/>
              <DownloadsButton parts = {parts} diagramTitle={diagramTitle} getDesignData={getDiagramData}/>
              <ShareButton remoteDiagram={remoteDiagram} onShare={() => setShowShareModal(true)} />
              <AnalyzeButton getDiagramData={getDiagramData} onActivate={() => setCurrentTabSlug('analysis')} />
              <SaveButton remoteDiagram={remoteDiagram} saveDiagram={saveDiagram} />
              <NewButton />
            </div>
        </div>
        <div className="flex-1 h-full border border-gray-200 border-t-0">
          <DesignTab
            show={currentTabSlug === 'design'}
            parts={parts}
            initialState={designTabInitialState}
            flowInst={flowInst}
            setFlowInst={setFlowInst}
          />
          <AnalysisTab
            show={currentTabSlug === 'analysis'}
          />
          <InfoModel show = {infoState} onClose = {() => setInfoState(false)}/>
        </div>
      </AnalysisDataContainer.Provider>
    </div>
  </>);
}

function StatusText({status}:{
  status: string;
}){
  return (
    <div 
    className={classNames('ml-3 px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 whitespace-nowrap')}>
      {status}
    </div>
  );
}

function ShareButton({remoteDiagram, onShare}: {
  remoteDiagram?: RemoteDiagram;
  onShare: () => void;
}) {
  const {authUser} = AuthContainer.useContainer();
  const isEnabled = Boolean(authUser && remoteDiagram && authUser.id === remoteDiagram.ownerUserID);
  
  return (
    <button
      type="button"
      disabled={!isEnabled}
      className={classNames('ml-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 whitespace-nowrap', isEnabled? 'bg-white hover:bg-gray-50' : 'bg-gray-300')}
      onClick={() => onShare()}
    >
      Share
    </button>
  );
}

function AnalyzeButton({getDiagramData, onActivate}: {
  getDiagramData: () => DiagramData | undefined;
  onActivate?: () => void;
}) {
  const {analysisInProgress, analyze} = AnalysisDataContainer.useContainer();
  const {handleError} = ErrorHandlerContainer.useContainer();
  
  return (
    <button
      type="button"
      disabled={analysisInProgress}
      className={classNames('ml-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 whitespace-nowrap', analysisInProgress? 'bg-gray-300' : 'bg-white hover:bg-gray-50')}
      onClick={() => {
        analyze(getDiagramData())
        .catch(err => handleError(err));
        onActivate?.();
      }}
    >
      {analysisInProgress? (<>
        Analyzing
        <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </>): (<>
        Analyze
      </>)}
    </button>
  );
}


function SaveButton({remoteDiagram, saveDiagram}: {
  remoteDiagram?: RemoteDiagram;
  saveDiagram: (authUser: AuthUser) => Promise<void>;
}) {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const {handleError} = ErrorHandlerContainer.useContainer();
  const {authUser, showAuthLoginModal} = AuthContainer.useContainer();
  
  function onSave() {
    if (!authUser) {
      showAuthLoginModal();
      return;
    }
    
    if (saveInProgress) return;
    setSaveInProgress(true);
    
    saveDiagram(authUser)
    .catch(err => handleError(err))
    .finally(() => setSaveInProgress(false));
  }
  
  const canSave = Boolean(
    authUser &&
    (!remoteDiagram || authUser.id === remoteDiagram.ownerUserID)
  );
  const isEnabled = canSave && !saveInProgress;
  
  return (
    <button
      type="button"
      disabled={!isEnabled}
      className={classNames('ml-3 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 whitespace-nowrap', isEnabled? 'text-white bg-orange-600 hover:bg-orange-700' : 'bg-gray-300 text-gray-700')}
      onClick={() => onSave()}
    >
      {saveInProgress? (<>
        Saving
        <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </>): (<>
        Save
      </>)}
    </button>
  );
}

function InfoButton({setInfoState}: {
  setInfoState: (infoState: boolean) => void;
}) {
  return (
    <button
      type="button"
      className={classNames('ml-3 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-gray-200 whitespace-nowrap')}
      onClick={() => setInfoState(true)}
    >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    </button>
  );
}

function NewButton() {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className={classNames('ml-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 whitespace-nowrap bg-white hover:bg-gray-50')}
      onClick={() => navigate(`/?${new URLSearchParams({diagramID: BLANK_DIAGRAM_ID}).toString()}`)}
    >
      New
    </button>
  );
}

