import {useEffect, useState} from 'react';
import * as HeroIcons from '@heroicons/react/outline';
import {classNames} from '../utils/classNames';
import AuthContainer, {AuthUser} from '../components/AuthContainer';
import ErrorHandlerContainer from '../components/ErrorHandlerContainer';
import config from '../config';
import {DiagramListItem} from '../types';
import {useNavigate} from 'react-router-dom';

export default function ListDiagramsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [myDiagramListItems, setMyDiagramListItems] = useState<DiagramListItem[]>();
  const [othersDiagramListItems, setOthersDiagramListItems] = useState<DiagramListItem[]>();
  const {authUser, showAuthLoginModal} = AuthContainer.useContainer();
  const {handleError} = ErrorHandlerContainer.useContainer();
  const navigate = useNavigate();
  
  useEffect(() => {
    void refreshSavedDiagrams(authUser);
  }, [authUser]);
  
  async function refreshSavedDiagrams(authUser: AuthUser | undefined) {
    if (!authUser) {
      setMyDiagramListItems(undefined);
      return;
    }
    
    if (!config.apiURL) {
      throw new Error(`API URL not configured.`);
    }
    
    if (isLoading) return;
    setIsLoading(true);
    setMyDiagramListItems(undefined);
    setErrorMessage(undefined);
    try {
      const res = await fetch(`${config.apiURL}/listDiagrams`, {
        headers: {
          'Authorization': `Bearer ${authUser.authToken}`
        },
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        throw new Error(`Non-2XX response when fetching listDiagrams: ${res.status}\n${bodyStr}`);
      }
      const {diagrams: diagramListItems} = (await res.json()) as {diagrams: DiagramListItem[]};
      
      const myDiagramListItems: DiagramListItem[] = [];
      const otherDiagramListItems: DiagramListItem[] = [];
      for (const diagramListItem of diagramListItems) {
        (
          diagramListItem.ownerUserID === authUser.id
          ? myDiagramListItems
          : otherDiagramListItems
        ).push(diagramListItem);
      }
      
      myDiagramListItems.sort((a, b) =>
        b.updatedTSStr.localeCompare(a.updatedTSStr)
      );
      otherDiagramListItems.sort((a, b) =>
        compareNullableStrings(a.ownerFirstName, b.ownerFirstName) ||
        compareNullableStrings(a.ownerLastName, b.ownerLastName) ||
        compareNullableStrings(a.title, b.title) ||
        b.updatedTSStr.localeCompare(a.updatedTSStr)
      );
      
      setMyDiagramListItems(myDiagramListItems);
      setOthersDiagramListItems(otherDiagramListItems);
    }
    catch(err) {
      setErrorMessage('An error occured.');
      handleError(err);
    }
    finally {
      setIsLoading(false);
    }
  }
  
  
  return (
    <div>
      <header>
        <h1 className="text-3xl font-bold leading-tight text-gray-900">My Designs</h1>
      </header>
      <main className="pt-10">
        {!authUser? (
          <p>
            You must
            <button
              className="mx-1 inline-block items-center px-2 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              onClick={() => showAuthLoginModal()}
            >
              Sign in
            </button>
            to save designs.
          </p>
        ) : (<>
          {isLoading? (
            <p>
              Loading...
              <svg className="animate-spin ml-2 -mr-1 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </p>
          ) : errorMessage? (
            <p className="text-red-600">{errorMessage}</p>
          ) : (
            <>
            {myDiagramListItems? (
              <table className="divide-y sm:max-w-xl divide-gray-200 text-left">
                <thead className="sticky top-0 overflow-hidden bg-gray-50 shadow border-b border-gray-200 sm:rounded-lg text-sm text-gray-500 uppercase tracking-wider">
                  <tr>
                    <th scope="col" className="px-2 w-0"><span className="w-6 h-6 inline-block" /></th>
                    <th scope="col" className="pr-3 py-2">Name</th>
                    <th scope="col" className="pl-2 pr-4 py-2">Last Updated</th>
                  </tr>
                </thead>  
                <tbody className="text-gray-900">
                  {myDiagramListItems.length === 0? (
                    <tr className="bg-white">
                      <td className="px-2"></td>
                      <td className="pr-3 py-2">No saved designs.</td>
                      <td className="pl-2 pr-4 py-2"></td>
                    </tr>
                  ) : myDiagramListItems.map((diagramListItem, i) => {
                    return (
                      <tr
                        key={diagramListItem.id}
                        className={classNames(i % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer')}
                        onClick={() => navigate(`/?${new URLSearchParams({diagramID: diagramListItem.id}).toString()}`)}
                      >
                        <td className="px-2 text-orange-500">
                          <button className="invisible group-hover:visible">
                            <HeroIcons.PencilAltIcon className="w-6 h-6 inline-block" />
                          </button>
                        </td>
                        <td className="pr-3 py-2">{diagramListItem.title}</td>
                        <td className="pl-2 pr-4 py-2 whitespace-nowrap text-gray-500 text-sm">{new Date(diagramListItem.updatedTSStr).toLocaleString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <></>
            )}
            
            {othersDiagramListItems && othersDiagramListItems.length > 0? (<>
              <h2 className="text-2xl font-bold leading-tight text-gray-900 pt-5 pb-3">Other Designs</h2>
              <table className="divide-y sm:max-w-xl divide-gray-200 text-left">
                <thead className="sticky top-0 overflow-hidden bg-gray-50 shadow border-b border-gray-200 sm:rounded-lg text-sm text-gray-500 uppercase tracking-wider">
                  <tr>
                    <th scope="col" className="w-0"><span className="w-6 h-6 inline-block" /></th>
                    <th scope="col" className="pr-2 py-2">First Name</th>
                    <th scope="col" className="px-2 py-2">Last Name</th>
                    <th scope="col" className="px-2 py-2">Company</th>
                    <th scope="col" className="px-2 py-2">Diagram</th>
                    <th scope="col" className="pl-2 py-2">Last Updated</th>
                  </tr>
                </thead>  
                <tbody className="text-gray-900">
                  {othersDiagramListItems.map((diagramListItem, i) => {
                    return (
                      <tr
                        key={diagramListItem.id}
                        className={classNames(i % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer')}
                        onClick={() => navigate(`/?${new URLSearchParams({diagramID: diagramListItem.id}).toString()}`)}
                      >
                        <td className="px-2 text-orange-500">
                          <button className="invisible group-hover:visible">
                            <HeroIcons.PencilAltIcon className="w-6 h-6 inline-block" />
                          </button>
                        </td>
                        <td className="pr-2 py-2">{diagramListItem.ownerFirstName || ''}</td>
                        <td className="px-2 py-2">{diagramListItem.ownerLastName || ''}</td>
                        <td className="px-2 py-2">{diagramListItem.ownerCompany || ''}</td>
                        <td className="px-2 py-2">{diagramListItem.title || ''}</td>
                        <td className="pl-2 py-2 whitespace-nowrap text-gray-500 text-sm">{new Date(diagramListItem.updatedTSStr).toLocaleString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>) : (
              <></>
            )}
            </>
          )}
          {!isLoading? (
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex items-center ml-3 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                onClick={() => {
                  void refreshSavedDiagrams(authUser);
                }}
              >
                <HeroIcons.RefreshIcon className="w-4 h-4 -ml-1 mr-2 inline-block" />
                Refresh
              </button>
            </div>
          ): <></>}
        </>)}
      </main>
    </div>
  );
}

function compareNullableStrings(a: string | null, b: string | null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return a.localeCompare(b);
}