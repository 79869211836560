import * as ReactFlow from 'react-flow-renderer';
import {createPartClassPropDefs, PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as SplitterIcon} from './icons/Splitter.svg';
import './Splitter.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: 'C', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: '1', initPosition: ReactFlow.Position.Right, offsetPercent: 25},
  {portKey: 'output1', label: '2', initPosition: ReactFlow.Position.Right, offsetPercent: 75},
];

const partClassDef: PartClassDef = {
  partClass: 'Combiner/Splitter',
  diagramIDPrefix: 'SP',
  handleDefs,
  propDefs: createPartClassPropDefs([
    {label: 'Manufacturer'},
    {label: 'Vendor Part Number'},
    {label: 'HM Part Number'},
    //{label: 'Description'},
    {label: 'Low Frequency (GHz)', format: 'number'},
    {label: 'High Frequency (GHz)', format: 'number'},
    {label: 'Connector Type'},
    {label: 'Max VSWR'},
    {label: 'Power Splitting (dB)', format: 'number'},
    {label: 'Phase Shift (Degrees)', format: 'number'},
    {label: 'Excess Insertion Loss (dB)', format: 'number'},
    {label: 'Max Amplitude Variation (dB)', format: 'number'},
    {label: 'Max Phase Variation (Degrees)', format: 'number'},
    {label: 'Max Power (W)', format: 'number'},
    {label: 'Isolation (dB)', format: 'number'},
    //{label: 'Price', format: 'USD'},
    //{label: 'Lead Time (weeks)', format: 'number'},
    {label: 'Datasheet URL', format: 'URL'},
    //{label: 'Operating Temperature Range'},
  ]),
  FlowNodeComponent: ({data}) => {
    const atr = data.part?.propDict['Excess Insertion Loss (dB)'];
    const text = atr !== undefined? String(atr) + 'dB' : '';
    return(
      <div className={`flow-node splitter-node ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <SplitterIcon/>
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        <div className="label">{text}</div>
      </div>
    );
  }
};
export default partClassDef;
