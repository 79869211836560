export function deleteUndefined<T>(obj: T) {
  return deleteKeys(obj, undefined);
}

export function deleteNulls<T>(obj: T) {
  return deleteKeys(obj, null);
}

export function deleteKeys<T, U>(obj: T, val: U) {
  for (const key in obj) {
    if (obj[key] === val as unknown) {
      delete obj[key];
    }
  }
  return obj as {[P in keyof T]: U extends T[P]? Exclude<T[P], U> | undefined : T[P]};
}

export function overlapObjects<T extends Record<string, unknown>>(a: T, b: T, depth?: number) : T;
export function overlapObjects<T extends Record<string, unknown>>(a: T | undefined, b: T | undefined, depth?: number): T | undefined;
export function overlapObjects<T extends Record<string, unknown>>(a: T | undefined, b: T | undefined, depth = 1): T | undefined {
  if (b === undefined) {
    return a;
  }
  if (
    !a || typeof a !== 'object' || Array.isArray(a) ||
    !b || typeof b !== 'object' || Array.isArray(b)
  ) {
    return b;
  }
  
  const c = {...a};
  for (const key in b) {
    if (b[key] === undefined) {
      continue;
    }
    
    if (depth > 1) {
      // @ts-expect-error
      c[key] = overlapObjects(c[key], b[key], depth - 1);
    }
    else {
      c[key] = b[key];
    }
  }
  
  return c;
}

export function mapObject<T extends Record<string, unknown>, U>(
  obj: T,
  cb: (value: T[keyof T], key: string, obj: T) => U
) : {[P in keyof T]: U} {
  const newObj = {} as {[P in keyof T]: U};
  for (const key in obj) {
    // eslint-disable-next-line callback-return
    newObj[key] = cb(obj[key], key, obj);
  }
  return newObj;
}

export function deepJSONObjEquals(a: any, b: any): boolean {
  for (const key in a) {
    const valA = a[key];
    const valB = b[key];
    
    if (typeof valA === 'object' && valA !== null) {
      if (typeof valB !== 'object' || valB === null){
        return false;
      }
      if (!deepJSONObjEquals(valA, valB)) {
        return false;
      }
    }
    else if (a[key] !== b[key]) {
      return false;
    }
  }
  for (const key in b) {
    if (b[key] !== undefined && !(key in a)) {
      return false;
    }
  }
  return true;
}