import {useState} from 'react';
import config from '../../config';
import {classNames} from '../../utils/classNames';
import AuthContainer from '../AuthContainer';
import {AuthFormProcState, AuthFormProps} from './AuthModal';
import {LinkButton} from '../LinkButton';
import * as HeroIcons from '@heroicons/react/outline';

export function ForgotPasswordForm({handleFormSubmit}: AuthFormProps) {
  const {initalEmail, showAuthLoginModal, showAuthRegisterModal} = AuthContainer.useContainer();
  const [procState, setProcState] = useState<AuthFormProcState>({});
  const [email, setEmail] = useState(initalEmail || '');
  
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    handleFormSubmit({event, procState, setProcState}, async () => {
      if (!email) {
        return {message: 'Missing data.'};
      }
      
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      
      const res = await fetch(`${config.apiURL}/forgotPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email
        })
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        let bodyObj;
        try {
          bodyObj = JSON.parse(bodyStr) as {
            errors?: {
              message: string;
              code?: string;
              details?: string;
            }[];
          };
        } catch(err) {/*noop*/}
        
        switch (bodyObj?.errors?.[0].code) {
          case 'INVALID_EMAIL': return {message: (<>
            Your email is not in our system. If you don't have an account, you
            can <LinkButton onClick={() => showAuthRegisterModal()}>create one</LinkButton>.
          </>)};
        }
        throw new Error(`Non-2XX response when fetching forgotPassword: ${res.status}\n${bodyStr}`);
      }
      
      return {
        wasSuccessful: true
      };
    });
  }
  
  if (procState.wasSuccessful) {
    return (
      <div className="space-y-6">
        <div>
          Password reset email sent. Please check your inbox and junk mail.
          Didn't get it? You can
          try <LinkButton onClick={() => setProcState({})}>sending another one</LinkButton>.
        </div>
        <div>
          After you reset your password, you can login.
        </div>
        <div>
          <button
            className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 bg-orange-600 hover:bg-orange-700"
            onClick={() => showAuthLoginModal(email)}
          >
            Contiue to Login
            <HeroIcons.ArrowRightIcon className="w-4 h-4 ml-2 -mr-1 mt-1" />
          </button>
        </div>
      </div>
    );
  }
  
  return (
     <form className="space-y-6" onSubmit={onSubmit}>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email address
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          disabled={procState.isLoading}
          className={classNames('w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2', procState.isLoading? 'bg-gray-300 focus:ring-gray-300' : 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500')}
        >
          {procState.isLoading? (<>
            Sending recovery email
            <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </>) : (<>
            Send Recovery Email
          </>)}
        </button>
      </div>
    
      <div className={classNames(
        !procState.message? 'hidden' : '',
        'bg-red-50 p-2 border text-red-600 border-red-400'
      )}>
        {procState.message}
      </div>
    </form>
  );
}