import type {AuthUser} from '../components/AuthContainer';
import config from '../config';

interface APIUser {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  isAdmin?: boolean;
}

export async function fetchAuthUser(userID: string, jwt: string) {
  const apiURL = config.apiURL;
  if (!apiURL) {
    throw new Error(`API URL not configured.`);
  }
  
  const url = new URL(`${apiURL}/getUserProfile`);
  url.searchParams.set('userID', userID);
  
  const res2 = await fetch(url.href, {
    headers: {
      'Authorization': `Bearer ${jwt}`
    }
  });
  if (!res2.ok) {
    const bodyStr = await res2.text();
    throw new Error(`Non-2XX response when fetching user profile: ${res2.status}\n${bodyStr}`);
  }
  
  const {user} = (await res2.json()) as {user: APIUser};
  const authUser: AuthUser = {
    id: user.id,
    authToken: jwt,
    email: user.email,
    name: buildFullName(user.firstName, user.lastName) || 'Unknown User',
    isAdmin: user.isAdmin || false,
  };
  return authUser;
}

function buildFullName(firstName: string | null, lastName: string | null) {
  if (firstName) {
    if (lastName) {
      return firstName + ' ' + lastName;
    }
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
}