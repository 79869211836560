import {useState} from 'react';
import config from '../../config';
import {classNames} from '../../utils/classNames';
import {clientHashPassword, validatePassword} from '../../utils/cryptic';
import AuthContainer from '../AuthContainer';
import {AuthFormProcState, AuthFormProps} from './AuthModal';
import {LinkButton} from '../LinkButton';
import * as HeroIcons from '@heroicons/react/outline';

export function RegisterForm({handleFormSubmit}: AuthFormProps) {
  const {showAuthLoginModal, showAuthForgotPasswordModal, showAuthEmailVerificationModal} = AuthContainer.useContainer();
  const [procState, setProcState] = useState<AuthFormProcState>({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    handleFormSubmit({event, procState, setProcState}, async () => {
      if (!firstName || !lastName || !email || !password || !passwordConfirm) {
        return {message: 'Missing data.'};
      }
      
      const passwordValidationMessage = validatePassword(password);
      if (passwordValidationMessage) {
        return {message: passwordValidationMessage};
      }
      
      if (password !== passwordConfirm) {
        return {message: 'Passwords do not match.'};
      }
      
      const passwordClientHashBase64 = await clientHashPassword(password);
      
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      
      const res = await fetch(`${config.apiURL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName,
          lastName,
          company: company || undefined,
          phone: phone || undefined,
          email,
          passwordClientHashBase64
        })
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        let bodyObj;
        try {
          bodyObj = JSON.parse(bodyStr) as {
            errors?: {
              message: string;
              code?: string;
              details?: string;
            }[];
          };
        } catch(err) {/*noop*/}
        
        switch (bodyObj?.errors?.[0].code) {
          case 'DUPLICATE_EMAIL': return {message: (<>
            Your email is already in our system. If you already have an account, you
            can <LinkButton onClick={() => showAuthLoginModal(email)}>login</LinkButton>.
            If you forgot your password, you
            can <LinkButton onClick={() => showAuthForgotPasswordModal(email)}>reset it</LinkButton>.
          </>)};
        }
        throw new Error(`Non-2XX response when fetching register: ${res.status}\n${bodyStr}`);
      }
      
      return {
        wasSuccessful: true
      };
    });
  }
  
  if (procState.wasSuccessful) {
    return (
      <div className="space-y-6">
        <div>
          Account created! Please check your inbox and junk mail for the verification email.
          Didn't get it? You can
          try <LinkButton onClick={() => showAuthEmailVerificationModal(email)}>sending another one</LinkButton>.
        </div>
        <div>
          After you verify your email, you can login.
        </div>
        <div>
          <button
            className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 bg-orange-600 hover:bg-orange-700"
            onClick={() => showAuthLoginModal(email)}
          >
            Contiue to Login
            <HeroIcons.ArrowRightIcon className="w-4 h-4 ml-2 -mr-1 mt-1" />
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <form className="space-y-6 divide-gray-200" onSubmit={onSubmit}>
      <div className="space-y-3">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            First name <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="given-name"
              name="given-name"
              type="text"
              autoComplete="given-name"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
            />
          </div>
        </div>
        
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            Last name <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="family-name"
              name="family-name"
              type="text"
              autoComplete="family-name"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={lastName}
              onChange={event => setLastName(event.target.value)}
            />
          </div>
        </div>
        
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            Company
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="organization"
              name="organization"
              type="text"
              autoComplete="organization"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={company}
              onChange={event => setCompany(event.target.value)}
            />
          </div>
        </div>
        
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            Phone
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="tel"
              name="tel"
              type="tel"
              autoComplete="tel"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={phone}
              onChange={event => setPhone(event.target.value)}
            />
          </div>
        </div>
        
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            Email address <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            Password <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="given-name" className="sm:mt-px sm:pt-2 block text-sm font-medium text-gray-700">
            Confirm password <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              id="password-confirm"
              name="password-confirm"
              type="password"
              autoComplete="off"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={passwordConfirm}
              onChange={event => setPasswordConfirm(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="text-sm">
          Already have an account? <LinkButton onClick={() => showAuthLoginModal()}>Login</LinkButton>
        </div>
      </div>
      
      <div>
        <button
          type="submit"
          disabled={procState.isLoading}
          className={classNames('w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2', procState.isLoading? 'bg-gray-300 focus:ring-gray-300' : 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500')}
        >
          {procState.isLoading? (<>
            Registering
            <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </>) : (<>
            Register
          </>)}
        </button>
      </div>
      
      <div className={classNames(
        !procState.message? 'hidden' : '',
        'bg-red-50 p-2 border text-red-600 border-red-400'
      )}>
        {procState.message}
      </div>
    </form>
  );
}