import {Fragment, FC} from 'react';
import {Disclosure, Transition, Popover} from '@headlessui/react';
import {MenuIcon, XIcon, ChevronDownIcon} from '@heroicons/react/outline';
import {classNames} from './utils/classNames';
import AuthContainer from './components/AuthContainer';
import {Link, NavLink} from 'react-router-dom';
import ErrorAlertModal from './components/ErrorAlertModal';
import AuthModal from './components/authModal/AuthModal';
import HMLogo from './images/HM_Logo.svg';
import './App.css';
const App: FC = ({children}) => {
  const {authUser, showAuthLoginModal, showAuthRegisterModal, clearStoredAuthUser} = AuthContainer.useContainer();
  
  const navigation = [
    {name: 'Designer', href: '/'},
    {name: 'My Designs', href: '/my-designs'},
    {name: 'Contact Us', href: '/contact-us'},
  ];
  if (authUser?.isAdmin) {
    navigation.push({name: 'Add Part', href: '/add-part'});
  }
  
  function onLogin() {
    showAuthLoginModal();
  }
  function onRegister() {
    showAuthRegisterModal();
  }
  function onLogout() {
    clearStoredAuthUser();
    window.location.href = '/';
  }
  
  return (<>
    <ErrorAlertModal />
    <AuthModal />
    <div className="h-full flex flex-col items-center">
      <Disclosure as="nav" className="flex-none w-full bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="h-8 w-auto"
                        src = {HMLogo}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({isActive}) => classNames(
                          isActive
                            ? 'border-orange-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        )}
                        aria-current="page"
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">

                  {/* Profile dropdown */}
                  {authUser? (
                    <Popover className="ml-3 relative">
                      
                      <Popover.Button className="border-transparent text-gray-500 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                        <span>Account</span>
                        <ChevronDownIcon className="ml-2 h-3 w-3" />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{minWidth: '12rem'}}>
                          <div className="pt-2 pb-3 border-b border-gray-200">
                            <div className="mx-3">
                              <div className="text-base font-medium text-gray-800">{authUser.name}</div>
                              <div className="text-sm font-medium text-gray-500">{authUser.email}</div>
                            </div>
                          </div>
                          <button
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => onLogout()}
                          >
                            Sign out
                          </button>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ) : (<>
                    <button
                      className="ml-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 whitespace-nowrap bg-white hover:bg-gray-50"
                      onClick={() => onRegister()}
                    >
                      Register
                    </button>
                    
                    <button
                      className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg--700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      onClick={() => onLogin()}
                    >
                      Sign in
                    </button>
                  </>)}
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              {({close}) => (<>
                <div className="pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({isActive}) => classNames(
                        isActive
                          ? 'bg-orange-50 border-orange-500 text-orange-700'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                      )}
                      aria-current="page"
                      onClick={() => close()}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="pt-3 pb-3 border-t border-gray-200">
                  {authUser? (<>
                    <div className="flex items-center px-4">
                      <div>
                        <div className="text-base font-medium text-gray-800">{authUser.name}</div>
                        <div className="text-sm font-medium text-gray-500">{authUser.email}</div>
                      </div>
                    </div>
                    <div className="mt-3 space-y-1">
                      <button
                        className="block w-full px-4 py-2 text-left text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                        onClick={() => onLogout()}
                      >
                        Sign out
                      </button>
                    </div>
                  </>) : (<>
                    <button
                      className="block w-full px-4 py-2 text-left text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      onClick={() => {
                        onRegister();
                        close();
                      }}
                    >
                      Register
                    </button>
                    <button
                      className="block w-full px-4 py-2 text-left text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      onClick={() => {
                        onLogin();
                        close();
                      }}
                    >
                      Sign in
                    </button>
                  </>)}
                </div>
              </>)}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="flex-1 w-full max-w-full px-4 sm:px-6 lg:px-8 py-4">
        {children}
      </div>
    </div>
  </>);
};
export default App;