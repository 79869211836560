import config from '../config';
import React, {useState} from 'react';
import ErrorHandlerContainer from '../components/ErrorHandlerContainer';
import AuthContainer from '../components/AuthContainer';
import {LinkButton} from '../components/LinkButton';
import {validatePassword, clientHashPassword} from '../utils/cryptic';
import {useLocation} from 'react-router-dom';
import {classNames} from '../utils/classNames';

export default function ContactPage() {
  const {handleError} = ErrorHandlerContainer.useContainer();
  const {showAuthForgotPasswordModal} = AuthContainer.useContainer();
  const location = useLocation();
  const [procState, setProcState] = useState({
    isLoading: false,
    wasSuccessful: false,
    message: undefined as string | JSX.Element | undefined
  });
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  
  const tokenID = new URLSearchParams(location.search).get('tokenID');
  const tokenSecretBase64 = new URLSearchParams(location.search).get('tokenSecretBase64');
  
  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    
    if (procState?.isLoading) return;
    setProcState({
      isLoading: true,
      wasSuccessful: false,
      message: undefined
    });
    
    processForm()
    .then(errorMessage => {
      setProcState({
        isLoading: false,
        wasSuccessful: !errorMessage,
        message: errorMessage,
      });
    })
    .catch(err => {
      handleError(err);
      setProcState({
        isLoading: false,
        wasSuccessful: false,
        message: 'An error occurred.',
      });
    });
    
    async function processForm(): Promise<string | JSX.Element | undefined> {
      if (!password || !passwordConfirm) {
        return 'Missing data.';
      }
      
      const passwordValidationMessage = validatePassword(password);
      if (passwordValidationMessage) {
        return passwordValidationMessage;
      }
      
      if (password !== passwordConfirm) {
        return 'Passwords do not match.';
      }
      
      const passwordClientHashBase64 = await clientHashPassword(password);
      
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      
      const res = await fetch(`${config.apiURL}/resetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          tokenID,
          tokenSecretBase64,
          passwordClientHashBase64,
        })
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        let bodyObj;
        try {
          bodyObj = JSON.parse(bodyStr) as {
            errors?: {
              message: string;
              code?: string;
              details?: string;
            }[];
          };
        } catch(err) {/*noop*/}
        
        switch (bodyObj?.errors?.[0].code) {
          case 'INVALID_TOKEN': return (<>
            This password reset link is invalid. Please try clicking the link from your email again.
            You can also try copying and pasting the URL into your browser. Or, you can
            try <LinkButton onClick={() => showAuthForgotPasswordModal()}>sending another one</LinkButton>.
          </>);
          case 'EXPIRED_TOKEN': return (<>
            This password reset link has expired. Please
            try <LinkButton onClick={() => showAuthForgotPasswordModal()}>sending another one</LinkButton>.
          </>);
        }
        throw new Error(`Non-2XX response when fetching resetPassword: ${res.status}\n${bodyStr}`);
      }
      
      return;
    }
  }
  
  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="mb-8">
        <header>
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Reset Password</h1>
        </header>
      </div>
      <main className="mt-8">
        {!tokenID || !tokenSecretBase64? (
          <div className="bg-red-50 p-2 border text-red-600 border-red-400">
            This password reset link is invalid. Please try clicking the link from your email again.
            You can also try copying and pasting the URL into your browser. Or, you can
            try <LinkButton onClick={() => showAuthForgotPasswordModal()}>sending another one</LinkButton>.
          </div>
        ) : procState.wasSuccessful? (
          <div>
            Password reset complete. You can now login with your new password.
          </div>
        ) : (
            <form className="space-y-6" onSubmit={onSubmit}>
              <div>
                <label htmlFor="given-name" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                  />
                </div>
              </div>

            <div>
              <label htmlFor="given-name" className="block text-sm font-medium text-gray-700">
                Confirm password
              </label>
              <div className="mt-1">
                <input
                  id="password-confirm"
                  name="password-confirm"
                  type="password"
                  autoComplete="off"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={passwordConfirm}
                  onChange={event => setPasswordConfirm(event.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={procState.isLoading}
                className={classNames('w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2', procState.isLoading? 'bg-gray-300 focus:ring-gray-300' : 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500')}
              >
                {procState.isLoading? (<>
                  Updating password
                  <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </>) : (<>
                  Update Password
                </>)}
              </button>
            </div>
            
            <div className={classNames(
              !procState.message? 'hidden' : '',
              'bg-red-50 p-2 border text-red-600 border-red-400'
            )}>
              {procState.message}
            </div>
          </form>
        )}
      </main>
    </div>
  );
}