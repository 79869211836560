import * as ReactFlow from 'react-flow-renderer';
import {PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {switchPropDefs} from '../switchPropDefs';
import {ReactComponent as Sp4tIcon1} from './icons/SP4T_1.svg';
import {ReactComponent as Sp4tIcon2} from './icons/SP4T_2.svg';
import {ReactComponent as Sp4tIcon3} from './icons/SP4T_3.svg';
import {ReactComponent as Sp4tIcon4} from './icons/SP4T_4.svg';
import './SP4T.css';

const stateDict: Record<string, {label: string; icon: typeof Sp4tIcon1}> = {
  'conn0': {label: 'Output 1', icon: Sp4tIcon1},
  'conn1': {label: 'Output 2', icon: Sp4tIcon2},
  'conn2': {label: 'Output 3', icon: Sp4tIcon3},
  'conn3': {label: 'Output 4', icon: Sp4tIcon4},
};
const states = Object.entries(stateDict).map(([key, {label}]) => ({key, label}));

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: 'C', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: '1', initPosition: ReactFlow.Position.Right, offsetPercent: -25},
  {portKey: 'output1', label: '2', initPosition: ReactFlow.Position.Right, offsetPercent:  25},
  {portKey: 'output2', label: '3', initPosition: ReactFlow.Position.Right, offsetPercent:  75},
  {portKey: 'output3', label: '4', initPosition: ReactFlow.Position.Right, offsetPercent: 125},
];

const partClassDef: PartClassDef = {
  partClass: 'SP4T',
  diagramIDPrefix: 'SW',
  states,
  propDefs: switchPropDefs,
  handleDefs,
  FlowNodeComponent: ({data}) => {
    const stateKey = data.stateKey || states[0].key;
    const Icon = stateDict[stateKey].icon;
    return (
      <div className={`flow-node sp4t-node state-${stateKey} ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <Icon />
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        {/* <div className="label">{data.label}</div> */}
      </div>
    );
  }
};
export default partClassDef;
