import {Dialog, Transition} from '@headlessui/react';
import {Fragment} from 'react';
import ErrorHandlerContainer from '../../components/ErrorHandlerContainer';
import AuthContainer from '../AuthContainer';
import * as HeroIcons from '@heroicons/react/outline';
import HMLogo from './../../images/HM_Logo.svg';
import React from 'react';
import {LoginForm} from './LoginForm';
import {RegisterForm} from './RegisterForm';
import {ForgotPasswordForm} from './ForgotPasswordForm';
import {EmailVerificationForm} from './EmailVerificationForm';

export enum AuthModelMode {
  'LOGIN',
  'REGISTER',
  'FORGOT_PASSWORD',
  'EMAIL_VERIFICATION'
}
export interface AuthFormProps {
  handleFormSubmit: HandleFormSubmitFn;
}
export interface AuthFormProcState {
  isLoading?: boolean;
  wasSuccessful?: boolean;
  message?: string | JSX.Element;
}

type HandleFormSubmitFn = (
  options: {
    event: React.FormEvent<HTMLFormElement>;
    procState: AuthFormProcState;
    setProcState: React.Dispatch<React.SetStateAction<AuthFormProcState>>;
  },
  processForm: () => Promise<{
    wasSuccessful?: boolean;
    message?: string | JSX.Element;
    shouldClose?: boolean;
  }>
) => void;

const formConfigDict = {
  [AuthModelMode.LOGIN]: {
    FormComponent: LoginForm,
    title: 'Sign in to your account'
  },
  [AuthModelMode.REGISTER]: {
    FormComponent: RegisterForm,
    title: 'Create a new account'
  },
  [AuthModelMode.FORGOT_PASSWORD]: {
    FormComponent: ForgotPasswordForm,
    title: 'Password recovery'
  },
  [AuthModelMode.EMAIL_VERIFICATION]: {
    FormComponent: EmailVerificationForm,
    title: 'Verify email address'
  },
};

export default function AuthModal() {
  const {handleError} = ErrorHandlerContainer.useContainer();
  const {showAuthModal, authModalMode, hideAuthModal} = AuthContainer.useContainer();
  
  function onClose() {
    hideAuthModal();
  }
  
  const handleFormSubmit: HandleFormSubmitFn = ({event, procState, setProcState}, processForm) => {
    event.preventDefault();
    
    if (procState?.isLoading) return;
    setProcState({
      isLoading: true,
      wasSuccessful: false,
      message: undefined
    });
    
    processForm()
    .then(result => {
      let shouldContinueLoading = false;
      if (result.shouldClose) {
        hideAuthModal();
        shouldContinueLoading = true;
      }
      
      setProcState({
        isLoading: shouldContinueLoading,
        wasSuccessful: result.wasSuccessful || false,
        message: result.message,
      });
    })
    .catch(err => {
      handleError(err);
      setProcState({
        isLoading: false,
        wasSuccessful: false,
        message: 'An error occurred.',
      });
    });
  };
  
  const formConfig = formConfigDict[authModalMode];
  
  return (
    <Transition.Root show={showAuthModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" unmount onClose={() => onClose()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  onClick={() => onClose()}
                >
                  <span className="sr-only">Close</span>
                  <HeroIcons.XIcon className="h-8 w-8 sm:h-6 sm:w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mb-8 sm:mx-auto sm:w-full sm:max-w-md">
                  <img
                    className="mx-auto h-12 w-auto"
                    src={HMLogo}
                    alt="HM"
                  />
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {formConfig?.title}
                  </h2>
                  {/* <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      start your 14-day free trial
                    </a>
                  </p> */}
                </div>
                
                {formConfig? <formConfig.FormComponent handleFormSubmit={handleFormSubmit} /> : <></>}
                
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
