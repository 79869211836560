import React from 'react';
import * as ReactFlow from 'react-flow-renderer';
import {PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as SourceIcon} from './icons/Source_R.svg';
import './Source.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'output0', label: '1', initPosition: ReactFlow.Position.Right},
];

const partClassDef: PartClassDef = {
  partClass: 'Source',
  diagramIDPrefix: 'P',
  hasNoPart: true,
  handleDefs,
  FlowNodeComponent: ({data}) => (
    <div className={`flow-node source-node ${getRotClassName(data.orientation)}`}>
      <SourceIcon/>
      <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
      <div className="diagram-id">{data.diagramID}</div>
      {/* <div className="label">{data.label}</div> */}
    </div>
  )
};
export default partClassDef;