import * as ReactFlow from 'react-flow-renderer';
import {createPartClassPropDefs, PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as BidirectionalCouplerIcon} from './icons/Bidirectional_Coupler.svg';
import './BidirectionalCoupler.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: 'In',  initPosition: ReactFlow.Position.Left,   offsetPercent: 37},
  {portKey: 'output0', label: 'Out', initPosition: ReactFlow.Position.Right,  offsetPercent: 37},
  {portKey: 'output1', label: 'FWD', initPosition: ReactFlow.Position.Bottom, offsetPercent: 25},
  {portKey: 'output2', label: 'REV', initPosition: ReactFlow.Position.Bottom, offsetPercent: 75},
];

const partClassDef: PartClassDef = {
  partClass: 'Bidirectional Coupler',
  diagramIDPrefix: 'C',
  handleDefs,
  propDefs: createPartClassPropDefs([
    {label: 'Manufacturer'},
    {label: 'Vendor Part Number'},
    {label: 'HM Part Number'},
    //{label: 'Description'},
    {label: 'Low Frequency (GHz)', format: 'number'},
    {label: 'High Frequency (GHz)', format: 'number'},
    {label: 'Connector Type'},
    {label: 'Max VSWR'},
    {label: 'Mean Coupling (dB)', format: 'number'},
    {label: 'Max Amplitude Variation (dB)', format: 'number'},
    {label: 'Max Power (W)', format: 'number'},
    {label: 'Direct Line Max Insertion Loss (dB)', format: 'number'},
    {label: 'Directivity (dB)', format: 'number'},
    //{label: 'Price', format: 'USD'},
    //{label: 'Lead Time (weeks)', format: 'number'},
    {label: 'Datasheet URL', format: 'URL'},
    //{label: 'Operating Temperature Range'},
  ]),
  FlowNodeComponent: ({data}) => (
    <div className={`flow-node bicpl-node ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
      <BidirectionalCouplerIcon/>
      <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
      <div className="diagram-id">{data.diagramID}</div>
    </div>
  )
};
export default partClassDef;
