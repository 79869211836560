import {PartClassHandleDef} from './partClassDef';
import * as ReactFlow from 'react-flow-renderer';
import {orientPosition} from './orientPosition';

export function FlowHandleSet(data: {
  handleDefs: PartClassHandleDef[];
  orientation: number;
}) {
  return (<>
    {data.handleDefs.map(handleDef => (
      <ReactFlow.Handle
        key={handleDef.portKey}
        type="source"
        id={handleDef.portKey}
        {...orientPosition(handleDef.initPosition, data.orientation, handleDef.offsetPercent)}
      />
    ))}
  </>);
}