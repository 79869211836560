import {useState} from 'react';
import {createContainer} from 'unstated-next';

const ErrorHandlerContainer = createContainer(() => {
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);
  
  function handleError(err: unknown) {
    console.error(err);
    addError(err instanceof Error? (err.message + (err.stack? '\n' + err.stack : '')) : String(err));
    showErrorAlert();
  }
  function addError(errorMessage: string) {
    setErrorMessages(errorMessages => errorMessages.concat([errorMessage]));
  }
  function clearErrors() {
    setErrorMessages([]);
  }
  function showErrorAlert() {
    setErrorAlertOpen(true);
  }
  function hideErrorAlert() {
    setErrorAlertOpen(false);
  }
  
  return {
    handleError,
    addError,
    clearErrors,
    hideErrorAlert,
    showErrorAlert,
    errorAlertOpen,
    errorMessages,
  };
});
export default ErrorHandlerContainer;
