import React from 'react';
import * as ReactFlow from 'react-flow-renderer';
import {createPartClassPropDefs, PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as LPFIcon} from './icons/LowPass.svg';
import {ReactComponent as HPFIcon} from './icons/HighPass.svg';
import {ReactComponent as BPFIcon} from './icons/BandPass.svg';
import {ReactComponent as BSFIcon} from './icons/BandStop.svg';
import './Filter.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: '1', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: '2', initPosition: ReactFlow.Position.Right}
];

const filterDict: Record<string, {icon: typeof LPFIcon}> = {
  'Low Pass': {icon: LPFIcon},
  'High Pass': {icon: HPFIcon},
  'Band Pass': {icon: BPFIcon},
  'Band Stop': {icon: BSFIcon},
};

const partClassDef: PartClassDef = {
  partClass: 'Filter',
  diagramIDPrefix: 'FL',
  handleDefs,
  propDefs: createPartClassPropDefs([
    {label: 'Manufacturer'},
    {label: 'Vendor Part Number'},
    {label: 'HM Part Number'},
    //{label: 'Description'},
    {label: 'Type', format: 'string'},
    {label: 'Low Frequency (GHz)', format: 'number'},
    {label: 'High Frequency (GHz)', format: 'number'},
    {label: 'Stop Band Supression (dB)', format: 'number'},
    //{label: 'Price', format: 'USD'},
    //{label: 'Lead Time (weeks)', format: 'number'},
    {label: 'Datasheet URL', format: 'URL'},
    //{label: 'Operating Temperature Range'},
  ]),
  FlowNodeComponent: ({data}) => { 
    const filterType =  data.part?.propDict ?  String(data.part?.propDict['Type']) : 'Low Pass';
    const Icon = filterDict[filterType].icon || filterDict['Low Pass'].icon;
    return(
    <div className={`flow-node amp-node ${getRotClassName(data.orientation)} ${data.part?.id ? 'haspart':'nopart'}`}>
      <Icon/>
      <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
      <div className="diagram-id">{data.diagramID}</div>
      <div className="label">{data.label}</div>
    </div>
    );
    }
};
export default partClassDef;