import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { DiagramData,Part, FlowNode } from '../types';
import * as ReactFlow from 'react-flow-renderer';
import { deserializeDiagramData } from '../utils/diagramHelper';
import  {writeFileXLSX, utils}  from 'xlsx';

function classNames(...classes:string[]) {
  return classes.filter(Boolean).join(' ');
}

function pad(num: number, size:number): string {
  let numstr = String(num);
  while (numstr.length < size) numstr = '0' + numstr;
  return numstr;
}

function generateBOM(diagramData: DiagramData | undefined,parts: Part[]): object[] {
  const listOfBomRows: Array<{
    'Find Number': string;
    'Qty': number;
    'Vendor Part Number': string;
    'Description':  string;
    'HM Part Number':  string;
    'Manufacturer':   string;
    'Reference Designators':   string;
    }> = [];
  let index = 1;
  listOfBomRows.length = 0; //clears BOM array. Ensures each button press is a new BOM.
  if (!diagramData) return [];
  const desDiagramData = deserializeDiagramData(diagramData,parts);
  const nodes = desDiagramData.elements.filter(x=> ReactFlow.isNode(x)) as FlowNode[];
  for (const node of nodes){
    if (!node.data?.partClassDef.hasNoPart) {
      const PN = node.data?.part?.propDict['Vendor Part Number'] || '';
      const Mfg = node.data?.part?.propDict['Manufacturer'] || '';
      const ID = node.data?.diagramID as string;
      if (listOfBomRows.filter(x=> x['Vendor Part Number'] === PN && x['Manufacturer'] === Mfg).length === 0){
        listOfBomRows.push({
        'Find Number': pad(index,4),
        'Qty': 1,
        'Vendor Part Number': node.data?.part?.propDict['Vendor Part Number'] as string || '',
        'Description':  node.data?.part?.propDict['Description']  as string || '',
        'HM Part Number':  node.data?.part?.propDict['HM Part Number']  as string || '',
        'Manufacturer':   node.data?.part?.propDict['Manufacturer']  as string || '',
        'Reference Designators':   node.data?.diagramID || '',
        });
        index +=1;
      }
      else{
        listOfBomRows.filter(x=> x['Vendor Part Number'] === PN && x['Manufacturer'] === Mfg)[0].Qty += 1;
        listOfBomRows.filter(x=> x['Vendor Part Number'] === PN && x['Manufacturer'] === Mfg)[0]['Reference Designators'] += ', ' + ID;
      }
    }
    }
  return listOfBomRows;
}


export default function DownloadsButton({parts,diagramTitle,getDesignData}: {
  parts: Part[];
  diagramTitle: string;
  getDesignData: () => DiagramData | undefined;
}) {

  return (
    <Menu as="div" className="relative inline-block text-left ml-3">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500">
          Download
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 ml-3" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-40 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
          {/* <Menu.I tem>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-not-invisible '
                  )}
                >
                  Datasheet (Coming Soon!)
                </div>
              )}
            </Menu.Item> */}
            <Menu.Item
            onClick={() => {
              const designData = getDesignData();
              if (!designData) return;
              const BOM = generateBOM(designData,parts);
              console.log(BOM);
              if (BOM.length > 0) {
                const workbook = utils.book_new();
                const worksheet = utils.json_to_sheet(BOM);
                utils.book_append_sheet(workbook, worksheet, 'BOM');
                writeFileXLSX(workbook, diagramTitle+'.xlsx');
              }
          }
            
            }
            >
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Bill of Material
                </div>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-not-allowed'
                  )}
                >
                  Zuken Import File
                </div>
              )}
            </Menu.Item> */}
            {/* <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-not-allowed'
                  )}
                >
                  TouchStone File
                </div>
              )}
            </Menu.Item> */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}