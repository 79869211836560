import {useState, useEffect, Fragment, useRef, useMemo,KeyboardEvent } from 'react';
import ReactFlowContainer, * as ReactFlow from 'react-flow-renderer';
import {classNames} from '../../utils/classNames';
import './DesignTab.css';
import './InfoModal.css';
import {Dialog, Menu, Transition} from '@headlessui/react';
import * as HeroIcons from '@heroicons/react/outline';
import {partClassDefs, partClassDefDict} from '../../partClasses/allPartClassDefs';
import {PartClassDef, PartClassPropFormat} from '../../partClasses/partClassDef';
import CustomEdge from '../../components/CustomEdge';
import * as uuid from 'uuid';
import {DiagramData, FlowEdge, FlowElements, FlowInstance, FlowNode, FlowNodeData,  FlowEdgeData, Part} from '../../types';
import {deserializeDiagramData} from '../../utils/diagramHelper';

export interface DesignTabInitialState {
  diagramData?: DiagramData;
}

export default function DesignTab(props: Parameters<typeof DesignTab2>[0]) {
  return (
    <ReactFlow.ReactFlowProvider>
      <DesignTab2 {...props} />
    </ReactFlow.ReactFlowProvider>
  );
}
function DesignTab2({show, parts, initialState, flowInst, setFlowInst}: {
  show: boolean;
  parts: Part[];
  initialState: DesignTabInitialState;
  flowInst?: FlowInstance;
  setFlowInst: (flowInst: FlowInstance) => void;
}) {
  const stateRef = useRef({
    diagramIDPrefixIncDict: {} as Record<string, number>,
  });
  const initalFlowData = useMemo(() => ({
    export: initialState.diagramData && deserializeDiagramData(initialState.diagramData, parts)
  }), [initialState, parts]);
  const flowWrapperRef = useRef<HTMLDivElement>(null);
  const [flowElements, setFlowElements] = useState<FlowElements>([]);
  const [selectedFlowElements, setSelectedFlowElements] = useState<(FlowNode | FlowEdge)[]>([]);
  const [copiedFlowElements, setCopiedFlowElements] = useState<(FlowNode | FlowEdge)[]>([]);
  const [mousePosition, setMousePosition] = useState<({x: number; y: number})>();
  const [contextMenuState, setContextMenuState] = useState({
    open: false,
    position: {x: 0, y: 0},
    targetFlowElements: [] as (FlowNode | FlowEdge)[],
  });
  const [partSearchModelState, setPartSearchModelState] = useState({
    open: false,
    targetFlowNode: undefined as FlowNode | undefined
  });

  const gridSize = 15;
  
  useEffect(() => {
    if (initalFlowData.export) {
      setFlowElements(initalFlowData.export.elements);
    }
    else {
      setFlowElements([
        createFlowNode({partClassDef: partClassDefDict.Source, position: {x: 150, y: 180}}),
        createFlowNode({partClassDef: partClassDefDict.Load, position: {x: 300, y: 180}}),
      ]);
    }
  }, [initalFlowData]);
  
  useEffect(() => {
    const initialDiagramIDPrefixIncDict = {} as Record<string, number>;
    for (const flowElement of initalFlowData.export?.elements ?? []) {
      if (!ReactFlow.isNode(flowElement)) continue;
      const flowNode = flowElement as FlowNode;
      if (!flowNode.data) continue;
      
      const partClassDef = flowNode.data.partClassDef;
      
      initialDiagramIDPrefixIncDict[partClassDef.diagramIDPrefix] = Math.max(
        initialDiagramIDPrefixIncDict[partClassDef.diagramIDPrefix] ?? 0,
        flowNode.data.diagramIDInc ?? 0
      );
    }
    stateRef.current.diagramIDPrefixIncDict = initialDiagramIDPrefixIncDict;
  }, [initalFlowData]);
  
  useEffect(() => {
    if (!flowInst) return;
    flowInst.setTransform({
      x: initalFlowData.export?.position[0] ?? 0,
      y: initalFlowData.export?.position[1] ?? 0,
      zoom: initalFlowData.export?.zoom ?? 1
    });
  }, [flowInst, initalFlowData]);
  
  // Pretty sure this not the best way to do this.
  const [rotatedFlowNodeInfo, setRotatedFlowNodeInfo] = useState<{flowNodeID: string; orientation: number}>();
  const updateFlowNodeInternals = ReactFlow.useUpdateNodeInternals();
  useEffect(() => {
    if (!rotatedFlowNodeInfo) return;
    updateFlowNodeInternals(rotatedFlowNodeInfo.flowNodeID);
  }, [rotatedFlowNodeInfo, updateFlowNodeInternals]);
  
  function getNextDiagramID(prefix: string) {
    const {diagramIDPrefixIncDict} = stateRef.current;
    diagramIDPrefixIncDict[prefix] ??= 0;
    const diagramIDInc = ++diagramIDPrefixIncDict[prefix];
    const diagramID = `${prefix}${diagramIDInc}`;
    return {
      diagramIDInc,
      diagramID
    };
  }
  //Do not like this solution - TODO - figure out why selection does not honor the grid. 
  const updateElementsPosition = (selectionNodes: FlowNode[]) => {
    setFlowElements((els) => {
      return els.map((el) => {
        for (const node of selectionNodes) {
          if (node.id === el.id) {
            return {
              ...el,
              position: {
                x: getNearestGridPosition(node.position.x),
                y: getNearestGridPosition(node.position.y),
              },
            };
          }
        }
        return el;
      });
    });
  };
  
  function pasteFlowElements(flowElementsToCopy: (FlowNode | FlowEdge)[], anchorPosition: {x: number; y: number}): void {
    // Split into nodes and edges.
    const flowNodesToCopy: FlowNode[] = [];
    const flowEdgesToCopy: FlowEdge[] = [];
    for (const flowElementToCopy of flowElementsToCopy) {
      if (ReactFlow.isNode(flowElementToCopy)) {
        flowNodesToCopy.push(flowElementToCopy);
      }
      else {
        flowEdgesToCopy.push(flowElementToCopy);
      }
    }
    
    // Find the left most and top most positions.
    let minX = flowNodesToCopy[0].position.x;
    let minY = flowNodesToCopy[0].position.y;
    for (const flowNode of flowNodesToCopy) {
      if (flowNode.position.x < minX) minX = flowNode.position.x;
      if (flowNode.position.y < minY) minY = flowNode.position.y;
    }

    // Copy nodes.
    const flowNodeCopyDict: Record<string, FlowNode> = {};
    for (const flowNodeToCopy of flowNodesToCopy) {
      if (!flowNodeToCopy.data) continue;
      const partClassDef = flowNodeToCopy.data.partClassDef;

      // Calculate position relative to paste anchor. Adjusts for Grid Spacing
      const newPosition = {
        x: getNearestGridPosition(anchorPosition.x + flowNodeToCopy.position.x - minX),
        y: getNearestGridPosition(anchorPosition.y + flowNodeToCopy.position.y - minY),
      };
      
      const newFlowNode = createFlowNode({
        partClassDef,
        position: newPosition,
        part       : flowNodeToCopy.data?.part,
        orientation: flowNodeToCopy.data?.orientation,
        label      : flowNodeToCopy.data?.label,
        stateKey   : flowNodeToCopy.data?.stateKey,
      });
      flowNodeCopyDict[flowNodeToCopy.id] = newFlowNode;
    }
    
    // Copy edges for which the source and target were copied.
    const newFlowEdges: FlowEdge[] = [];
    for (const flowEdgeToCopy of flowEdgesToCopy) {
      const newSourceFlowNode = flowNodeCopyDict[flowEdgeToCopy.source];
      const newTargetFlowNode = flowNodeCopyDict[flowEdgeToCopy.target];
      if (!newSourceFlowNode || !newTargetFlowNode) {
        continue;
      }
      if (!flowEdgeToCopy.sourceHandle || !flowEdgeToCopy.targetHandle) {
        continue;
      }
      
      newFlowEdges.push(createFlowEdge({
        sourceFlowNode: newSourceFlowNode,
        targetFlowNode: newTargetFlowNode,
        sourceFlowNodeHandleID: flowEdgeToCopy.sourceHandle,
        targetFlowNodeHandleID: flowEdgeToCopy.targetHandle,
        isTruncated: flowEdgeToCopy.data?.isTruncated,
      }));
    }
    
    setFlowElements(elements => elements.concat(
      Object.values(flowNodeCopyDict),
      newFlowEdges
    ));
    updateElementsPosition(Object.values(flowNodeCopyDict));
  }

  function createFlowNode(options: {
    partClassDef: PartClassDef;
    position: {
      x: number;
      y: number;
    };
    part?: Part;
    orientation?: number;
    label?: string;
    stateKey?: string;
  }): FlowNode {
    const {partClassDef} = options;
    const {diagramIDInc, diagramID} = getNextDiagramID(partClassDef.diagramIDPrefix);
    return {
      id: uuid.v4(),
      type: partClassDef.partClass,
      position: options.position,
      data: {
        partClassDef: partClassDef,
        diagramIDInc,
        diagramID,
        part: options.part,
        orientation: options.orientation || 0,
        label: options.label || partClassDef.partClass,
        stateKey: options.stateKey || (partClassDef.states && partClassDef.states.length > 0? partClassDef.states[0].key : undefined),
      }
    };
  }
  
  function createFlowEdge(options: {
    sourceFlowNode: FlowNode;
    targetFlowNode: FlowNode;
    sourceFlowNodeHandleID: string;
    targetFlowNodeHandleID: string;
    isTruncated?: boolean;
  }): FlowEdge {
    return {
      id: uuid.v4(),
      source: options.sourceFlowNode.id,
      target: options.targetFlowNode.id,
      sourceHandle: options.sourceFlowNodeHandleID,
      targetHandle: options.targetFlowNodeHandleID,
      data: {
        isTruncated: options.isTruncated || false,
        sourceFlowNode: options.sourceFlowNode,
        targetFlowNode: options.targetFlowNode,
      }
    };
  }
  
  function updateFlowNode<T>(flowNodeID: string, cb: (flowNodeData: FlowNodeData) => T) {
    const flowElement = flowElements.find(x => x.id === flowNodeID);
    if (!flowElement) return;
    if (!ReactFlow.isNode(flowElement)) return;
    const flowNode = flowElement as FlowNode;
    if (!flowNode.data) return;
    const rval = cb(flowNode.data); // eslint-disable-line callback-return
    setFlowElements(flowElements.slice(0));
    return rval;
  }
  
  function setFlowNodeStateKey(flowNode: FlowNode, stateKey: string) {
    updateFlowNode(flowNode.id, flowNodeData => {
      flowNodeData.stateKey = stateKey;
    });
  }
  function rotateFlowNode(flowNode: FlowNode, rotAmount: number = 1) {
    if (rotAmount < 0) {
      rotAmount = 4 + (rotAmount % 4);
    }
    updateFlowNode(flowNode.id, flowNodeData => {
      flowNodeData.orientation = (flowNodeData.orientation + rotAmount) % 4;
      setRotatedFlowNodeInfo({flowNodeID: flowNode.id, orientation: flowNodeData.orientation});
    });
  }
  function setFlowNodePart(flowNode: FlowNode, part: Part | undefined) {
    updateFlowNode(flowNode.id, flowNodeData => {
      flowNodeData.part = part;
      flowNodeData.label = part?.title ?? flowNodeData.partClassDef.partClass;
    });
  }
  function deleteFlowElements(elementsToRemove: ReactFlow.Elements) {
    setFlowElements(elements => ReactFlow.removeElements(elementsToRemove, elements));
  }
  
  function updateFlowEdge<T>(targetEdge: FlowEdge,cb: (flowEdgeData: FlowEdgeData) => T) {
    const flowElement = flowElements.find(x => x.id === targetEdge.id);
    if (!flowElement) return;
    if (!ReactFlow.isEdge(flowElement)) return;
    const flowEdge = flowElement as FlowEdge;
    if (!flowEdge.data) return;
    const rval = cb(flowEdge.data); // eslint-disable-line callback-return
    setFlowElements(flowElements.slice(0));
    return rval;
  }
  function toggleEdgeTruncated(targetEdge: FlowEdge): void {
    if (!targetEdge.data) return;
    
    updateFlowEdge(targetEdge, flowEdgeData => {
      flowEdgeData.isTruncated = !flowEdgeData.isTruncated;
    });
  }
  
  const onFlowDragOver: React.DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };
  
  const onFlowDrop: React.DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    const partClass = event.dataTransfer.getData('application/reactflow');
    const partClassDef = partClassDefDict[partClass];
    if (!partClassDef) return;
    if (!flowWrapperRef.current) return;
    if (!flowInst) return;
    
    const flowBounds = flowWrapperRef.current.getBoundingClientRect();
    const position = flowInst.project({
      x: getNearestGridPosition(event.clientX - flowBounds.left), // + (event.clientX % flowBounds.left),
      y: getNearestGridPosition(event.clientY - flowBounds.top), // + (event.clientY % flowBounds.left),
    });

    const newNode = createFlowNode({partClassDef, position});
    setFlowElements(elements => elements.concat([newNode]));
  };

  function getNearestGridPosition(position: number) {
    return Math.round(position / gridSize) * gridSize ;
  }

  function validateConnection(edgeOrConnection: ReactFlow.Edge | ReactFlow.Connection): boolean{
    //if the connection is looped back on iteself in any ways return false
    if (edgeOrConnection.source === edgeOrConnection.target) return false;
    //otherwise get nodes related to connection
    const nodes = flowElements.filter(x=>x.id === edgeOrConnection.target || x.id === edgeOrConnection.source) as FlowNode[];
    //first check for connections associtaed to the two nodes
    const connectedEdges = ReactFlow.getConnectedEdges(nodes,flowElements.filter(ReactFlow.isEdge));
    //for edge associated with the node check for an edge already present on either handle. If any are found, return false
    for (const edge of connectedEdges) {
        if (edge.target === edgeOrConnection.target && edge.targetHandle === edgeOrConnection.targetHandle) return false;
        if (edge.source === edgeOrConnection.source && edge.sourceHandle === edgeOrConnection.sourceHandle) return false;
        if (edge.target === edgeOrConnection.source && edge.targetHandle === edgeOrConnection.sourceHandle) return false;
        if (edge.source === edgeOrConnection.target && edge.sourceHandle === edgeOrConnection.targetHandle) return false;
    }
    return true;
    }

  function onFlowConnect(edgeOrConnection: ReactFlow.Edge | ReactFlow.Connection) {
    if (!validateConnection(edgeOrConnection)) {
      console.log('Invalid Connection!');
      return;
    }
    
    const sourceFlowNode = flowElements.find(x => x.id === edgeOrConnection.source) as FlowNode;
    const targetFlowNode = flowElements.find(x => x.id === edgeOrConnection.target) as FlowNode;
    
    if (!sourceFlowNode || !targetFlowNode) {
      return;
    }
    if (!edgeOrConnection.sourceHandle || !edgeOrConnection.targetHandle) {
      return;
    }
    
    const newFlowEdge = createFlowEdge({
      sourceFlowNode,
      targetFlowNode,
      sourceFlowNodeHandleID: edgeOrConnection.sourceHandle,
      targetFlowNodeHandleID: edgeOrConnection.targetHandle,
    });
    setFlowElements(elements => ReactFlow.addEdge({
      ...edgeOrConnection,
      ...newFlowEdge
    }, elements));
  }

  function onFlowElementsRemove(elementsToRemove: ReactFlow.Elements) {
    deleteFlowElements(elementsToRemove);
  }
  
  const showContextMenu = (event: React.MouseEvent<Element, MouseEvent>, targetFlowElements: (FlowNode | FlowEdge)[]) => {
    event.preventDefault();
    if (!flowWrapperRef.current) return;
    const flowBounds = flowWrapperRef.current.getBoundingClientRect();
    setContextMenuState({
      open: true,
      position: {
        x: event.clientX - flowBounds.left,
        y: event.clientY - flowBounds.top,
      },
      targetFlowElements,
    });
  };
  const hideContextMenu = () => {
    setContextMenuState(x => ({...x, open: false}));
  };
  
  const showPartSearchModal = (targetFlowNode: FlowNode) => {
    setPartSearchModelState({open: true, targetFlowNode});
  };
  const hidePartSearchModal = () => {
    setPartSearchModelState(x => ({...x, open: false}));
  };

  const handleKeyDown = (event: KeyboardEvent)=>{
   event.preventDefault();
   if((event.ctrlKey) && event.key === 'c') {
      setCopiedFlowElements(selectedFlowElements);
    }else if((event.ctrlKey) && event.key === 'v') {
      if (!flowInst || !mousePosition || !flowWrapperRef.current) return;
      const flowBounds = flowWrapperRef.current.getBoundingClientRect();
      const anchorPosition = flowInst.project({
        x: mousePosition.x - flowBounds.left,
        y: mousePosition.y - flowBounds.top,
      });
      pasteFlowElements(copiedFlowElements,anchorPosition);
    }else if((event.ctrlKey) && event.key === 'r') {
      const selectedFlowNodes = selectedFlowElements.filter(x => ReactFlow.isNode(x)) as FlowNode[];
      if (selectedFlowNodes.length === 1) rotateFlowNode(selectedFlowNodes[0],1);
    }else if((event.ctrlKey) && event.key === 'm') {
      console.log('CTRL+M Pressed');
    }
};


  return (
    <div className={classNames('w-full h-full flex relative select-none', show? '' : 'hidden')} style={{minHeight: '12rem'}}>
      <div className="w-full h-full flex-1" ref={flowWrapperRef} onKeyDown={handleKeyDown} tabIndex={0}>
        <ReactFlowContainer
          elements={flowElements}
          defaultPosition={initialState.diagramData?.flowData.position}
          defaultZoom={initialState.diagramData?.flowData.zoom}
          minZoom = {0.1}
          onLoad={flowInst => setFlowInst(flowInst)}
          onDragOver={onFlowDragOver}
          onDrop={onFlowDrop}
          onConnect={onFlowConnect}
          onElementsRemove={onFlowElementsRemove}
          nodeTypes={Object.fromEntries(partClassDefs.map(x => [x.partClass, x.FlowNodeComponent]))}
          edgeTypes={{default: CustomEdge}}//{{default: ReactFlow.SmoothStepEdge}}
          snapGrid={[gridSize,gridSize]}
          snapToGrid = {true}
          connectionMode={ReactFlow.ConnectionMode.Loose}
          connectionLineType={ReactFlow.ConnectionLineType.SmoothStep}
          deleteKeyCode="Delete"
          onNodeContextMenu={(event, flowNode) => showContextMenu(event, [flowNode])}
          onEdgeContextMenu={(event, flowEdge) => showContextMenu(event, [flowEdge])}
          onSelectionContextMenu={(event) => showContextMenu(event, selectedFlowElements)}
          onPaneContextMenu={(event) => showContextMenu(event, [])}
          onMouseDownCapture={() => hideContextMenu()}
          onMouseMove={(event) => setMousePosition({x:event.clientX , y:event.clientY})}
          onWheelCapture={() => hideContextMenu()}
          onSelectionDragStop={(event,nodes) =>  updateElementsPosition(nodes as FlowNode[])} //Do not like this solution - TODO - figure out why selection does not honor the grid. 
          onNodeDragStop={(event,node) => updateElementsPosition([node] as FlowNode[])} //Do not like this solution - TODO - figure out why selection does not honor the grid. 
          onSelectionChange={wrongSelectedFlowElements => {
            const rightSelectedFlowElements = (
              (wrongSelectedFlowElements || [])
              .map(w => flowInst?.getElements().find(r => r.id === w.id))
              .filter(x => x)
            );
            setSelectedFlowElements(rightSelectedFlowElements as (FlowNode | FlowEdge)[]);
          }}
        >
          <ReactFlow.Background
              variant= {ReactFlow.BackgroundVariant.Lines} 
              gap={gridSize*2}
              size={1}
            />
          <ReactFlow.Controls
            showZoom
            showFitView
            showInteractive
          />
        </ReactFlowContainer>
      </div>
      
      <ContextMenu
        position={contextMenuState.position}
        open={contextMenuState.open}
        targetFlowElements={contextMenuState.targetFlowElements}
        copiedFlowElements={copiedFlowElements}
        onRotateFlowNode={targetFlowNode => {
          rotateFlowNode(targetFlowNode);
          hideContextMenu();
        }}
        onSetFlowNodeStateKey={(targetFlowNode, stateKey) => {
          setFlowNodeStateKey(targetFlowNode, stateKey);
          hideContextMenu();
        }}
        onSearchForPart={targetFlowNode => {
          showPartSearchModal(targetFlowNode);
          hideContextMenu();
        }}
        onDeleteFlowElements={flowElements => {
          deleteFlowElements(flowElements);
          hideContextMenu();
        }}
        onCopyFlowElements={flowElements =>{
          setCopiedFlowElements(flowElements);
          hideContextMenu();
        }}
        onPasteFlowElements={(flowElements, flowViewPosition) => {
          if (!flowInst) return;
          const anchorPosition = flowInst.project({
            x: flowViewPosition.x,
            y: flowViewPosition.y,
          });
          pasteFlowElements(flowElements, anchorPosition);
          hideContextMenu();
        }}
        onToggleEdgeTruncated = {flowElements =>{
          toggleEdgeTruncated(flowElements);
          hideContextMenu();
        }}
      />
      
      <div
        className="flex-initial border-l p-4 w-56"
        onMouseDownCapture={() => hideContextMenu()}
      >
        <div className="grid-cols-2 grid content-evenly gap-1">
          {partClassDefs.map(partClassDef => (
            <div
              key={partClassDef.partClass}
              className={`px-1 py-1 w-full ${partClassDef.partClass.length > 10 ? 'col-span-2': 'col-span-1'} m-0 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 text-center cursor-grab overflow-hidden overflow-ellipsis`}
              draggable
              onDragStart={event => {
                event.dataTransfer.setData('application/reactflow', partClassDef.partClass);
                event.dataTransfer.effectAllowed = 'copy';
              }}
            >
              {partClassDef.partClass}
            </div>
          ))}
        </div>
        <PartInfoSection
          selectedFlowElements={selectedFlowElements}
          onDiagramIDUpdate={(targetFlowNode, newDiagramID) => {
            updateFlowNode(targetFlowNode.id, flowNodeData => {
              flowNodeData.diagramID = newDiagramID;
            });
          }}
        />
      </div>

      <PartSearchModal
        parts={parts}
        open={partSearchModelState.open}
        targetFlowNode={partSearchModelState.targetFlowNode}
        onClose={() => hidePartSearchModal()}
        onPartSelected={(targetFlowNode, part) => {
          setFlowNodePart(targetFlowNode, part);
          hidePartSearchModal();
        }}
      />
    </div>
  );
}

function ContextMenu({position, open, targetFlowElements, copiedFlowElements, onRotateFlowNode, onSetFlowNodeStateKey, onSearchForPart, onDeleteFlowElements, onCopyFlowElements, onPasteFlowElements,onToggleEdgeTruncated} : {
  position: {x: number; y: number};
  open: boolean;
  targetFlowElements: (FlowNode | FlowEdge)[];
  copiedFlowElements: (FlowNode | FlowEdge)[];
  onRotateFlowNode: (targetFlowNode: FlowNode) => void;
  onSetFlowNodeStateKey: (targetFlowNode: FlowNode, stateKey: string) => void;
  onSearchForPart: (targetFlowNode: FlowNode) => void;
  onDeleteFlowElements: (targetFlowElement: (FlowNode | FlowEdge)[]) => void;
  onCopyFlowElements: (flowElementsToCopy: (FlowNode | FlowEdge)[]) => void;
  onPasteFlowElements: (flowElementsToPaste: (FlowNode | FlowEdge)[], mousePosition: {x: number; y: number}) => void;
  onToggleEdgeTruncated: (targetFlowNode: FlowEdge) => void;
}) {
  
  let menuItemSections: JSX.Element[][] = [];
  
  menuItemSections.push(getClipboardContextMenuItemSection());
  
  if (targetFlowElements.length === 1 && ReactFlow.isNode(targetFlowElements[0])) {
    // Single node targeted.
    menuItemSections = menuItemSections.concat(getNodeTargetContextMenuItemSections(targetFlowElements[0]));
  }
  else if (targetFlowElements.length === 1 && ReactFlow.isEdge(targetFlowElements[0])) {
    // Single edge targeted.
    menuItemSections = menuItemSections.concat(getEdgeTargetContextMenuItemSections(targetFlowElements[0]));
  }
  
  menuItemSections.push(getGlobalContextMenuItemSection());
  
  return (
    <div className="flex flex-col absolute inset-0 invisible">
      <div className="flex-initial" style={{height: `${position.y}px`}}>
        </div>
        <div className="flex-none flex">
          <div className="flex-initial" style={{width: `${position.x}px`}}></div>
          <Menu>
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items static={true}
                className="flex-none visible origin-top-left z-10 m-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
              >
                {menuItemSections.map((menuItems, i) => (
                  <div key={i}>
                    {menuItems}
                  </div>
                ))}
              </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
  
  function getClipboardContextMenuItemSection() {
    const flowElementsToCopy = (
      targetFlowElements.some(x => ReactFlow.isNode(x)) // At least 1 node targeted.
      ? targetFlowElements
      : []
    );
    const flowElementsToPaste = copiedFlowElements;
    
    return [<>
      <Menu.Item key="copy" disabled={flowElementsToCopy.length === 0}>
        {({active, disabled}) => (
          <button
            className={classNames(
              disabled? 'text-gray-300'
              : active? 'bg-gray-100 text-gray-900'
              : 'text-gray-700',
              'group flex items-center px-4 py-3 text-sm w-full rounded-t-md'
            )}
            onClick={() => {
              if (disabled) return;
              onCopyFlowElements(flowElementsToCopy);
            }}
          >
            <HeroIcons.ClipboardCopyIcon
              className={classNames(
                disabled? 'text-gray-300'
                : active? 'text-gray-800'
                : 'text-gray-500',
                'mr-3 h-5 w-5')
              }
              aria-hidden="true"
            />
            Copy <kbd>Ctrl</kbd> + <kbd>C</kbd>
          </button>
        )}
      </Menu.Item>
      <Menu.Item key="paste" disabled={flowElementsToPaste.length === 0}>
        {({active, disabled}) => (
          <button
            className={classNames(
              disabled? 'text-gray-300'
              : active? 'bg-gray-100 text-gray-900'
              : 'text-gray-700',
              'group flex items-center px-4 py-3 text-sm w-full'
            )}
            onClick={() => {
              if (disabled) return;
              onPasteFlowElements(flowElementsToPaste, position);
            }}
          >
            <HeroIcons.ClipboardCopyIcon
              className={classNames(
                disabled? 'text-gray-300'
                : active? 'text-gray-800'
                : 'text-gray-500',
                'mr-3 h-5 w-5')
              }
              aria-hidden="true"
            />
            Paste <kbd>Ctrl</kbd> + <kbd>R</kbd>
          </button>
        )}
      </Menu.Item>
    </>];
  }

  function getNodeTargetContextMenuItemSections(targetFlowNode: FlowNode) {
    const partClassDef = targetFlowNode.data?.partClassDef;
    const targetFlowNodePartClassStates = partClassDef?.states ?? [];
    
    const menuItemSections: JSX.Element[][] = [];
    const nodeMenuItems: JSX.Element[] = [];
    
    if (!partClassDef?.hasNoPart) {
      nodeMenuItems.push(
        <Menu.Item key="selectPart">
          {({active}) => (
            <button
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-3 text-sm w-full'
              )}
              onClick={() => onSearchForPart(targetFlowNode)}
            >
              <HeroIcons.SearchIcon className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-800" aria-hidden="true" />
              Select Part
            </button>
          )}
        </Menu.Item>
      );
    }
    
    nodeMenuItems.push(<>
      <Menu.Item key="rotate">
        {({active}) => (
          <button
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'group flex items-center px-4 py-3 text-sm w-full'
            )}
            onClick={() => onRotateFlowNode(targetFlowNode)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-800"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
            Rotate <kbd>Ctrl</kbd> + <kbd>R</kbd>
          </button>
        )}
      </Menu.Item>
    </>);
    
    menuItemSections.push(nodeMenuItems);
    
    if (targetFlowNodePartClassStates.length > 0) {
      const stateMenuItems = targetFlowNodePartClassStates.map(partClassState => (
        <Menu.Item key={'state-' + partClassState.key}>
          {({active}) => (
            <button
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm w-full'
              )}
              onClick={() => onSetFlowNodeStateKey(targetFlowNode, partClassState.key)}
            >
              {targetFlowNode.data?.stateKey === partClassState.key
              ? <HeroIcons.CheckIcon className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-800" aria-hidden="true" />
              : <HeroIcons.ChevronRightIcon className={classNames(active? '' : 'invisible', 'mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-800')} aria-hidden="true" />
              }
              {partClassState.label}
            </button>
          )}
        </Menu.Item>
      ));
      menuItemSections.push(stateMenuItems);
    }
    
    return menuItemSections;
  }

  function getEdgeTargetContextMenuItemSections(targetFlowNode: FlowEdge) {
    return [<>
      <Menu.Item key="toggleRef">
        {({active}) => (
          <button
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'group flex items-center px-4 py-3 text-sm w-full'
            )}
            onClick={() => onToggleEdgeTruncated(targetFlowNode)}
          >
            <HeroIcons.SearchIcon className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-800" aria-hidden="true" />
            Toggle Ref.
          </button>
        )}
      </Menu.Item>
    </>];
  }
  
  function getGlobalContextMenuItemSection() {
    const flowElementsToDelete = targetFlowElements;
    return [<>
      <Menu.Item key="delete" disabled={flowElementsToDelete.length === 0}>
        {({active, disabled}) => (
          <button
            className={classNames(
              disabled? 'text-gray-300'
              : active? 'bg-gray-100 text-gray-900'
              : 'text-gray-700',
              'group flex items-center px-4 py-3 text-sm w-full'
            )}
            onClick={() => {
              if (disabled) return;
              onDeleteFlowElements(flowElementsToDelete);
            }}
          >
            <HeroIcons.TrashIcon
              className={classNames(
                disabled? 'text-gray-300'
                : active? 'text-gray-800'
                : 'text-gray-500',
                'mr-3 h-5 w-5')
              }
              aria-hidden="true"
            />
            Delete <kbd>Delete</kbd>
          </button>
        )}
      </Menu.Item>
    </>];
  }
}

function PartInfoSection({selectedFlowElements, onDiagramIDUpdate} : {
  selectedFlowElements: (FlowNode | FlowEdge)[];
  onDiagramIDUpdate: (targetFlowNode: FlowNode, newDiagramID: string) => void;
}){
  const selectedFlowNodes = selectedFlowElements.filter(x => ReactFlow.isNode(x)) as FlowNode[];
  
  if (selectedFlowNodes.length === 0) {
    return (
      <div className="my-1 border p-2 w-max-56 text-center mt-6 rounded-md">
        No Part Selected
      </div>
    );
  }
  else if (selectedFlowNodes.length > 1) {
    return (
      <div className="my-1 border p-2 w-max-56 text-center mt-6 rounded-md">
        Multiple Parts Selected
      </div>
    );
  }
  
  const targetFlowNode = selectedFlowNodes[0];
  
  const part = targetFlowNode.data?.part as Part;
  const targetPartClassDef = targetFlowNode?.data?.partClassDef;
  const propDefs = targetPartClassDef?.propDefs || [];
    return (
      <div
        className="flex-initial border p-4 py-1 w-max-56 mt-6 rounded-md max-h-[35vh] overflow-y-auto"
      >
          <div className="content-evenly">
            <div className="text-xs font-bold">
              Diagram ID:
            </div>
            <div className="py-1 text-sm">
              <input
                className="border-2 w-5/6"
                type="text"
                value={targetFlowNode.data?.diagramID}
                onChange={event => {
                  const newDiagramID = event.target.value;
                  onDiagramIDUpdate(targetFlowNode, newDiagramID);
                }}
              />
            </div>
          </div>

          {part ? propDefs.map(propDef => (
            <div key={propDef.key} className="content-evenly">
              <div className="text-xs font-bold">
                {propDef.key}
              </div>
              <div className="text-sm">
                {formatPartPropValue(part.propDict[propDef.key], propDef.format)}
              </div>
            </div>
          )): 
          <div className="my-1 text-center w-max-56 mt-6">
          No Part Data
          </div>}
      </div>
  );
}


function PartSearchModal({parts, open, targetFlowNode, onClose, onPartSelected} : {
  parts?: Part[];
  open: boolean;
  targetFlowNode?: FlowNode;
  onClose: () => void;
  onPartSelected: (targetFlowNode: FlowNode, part: Part) => void;
}) {
  const [searchStr, setSearchStr] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  
  const targetPartClassDef = targetFlowNode?.data?.partClassDef;
  
  const searchResults = useMemo(() => {
    if (!parts || !targetPartClassDef) {
      return [];
    }
    const propDefs = targetPartClassDef.propDefs || [];
    
    const searchItems = (
      parts
      .filter(part => part.partClassDef === targetPartClassDef)
      .map(part => {
        const elements: {val: string; weight: number}[] = [];
        
        for (let i = 0; i < propDefs.length; ++i) {
          const propDef = propDefs[i];
          if (propDef.format !== 'string') continue;
          
          const val = part.propDict[propDef.key];
          if (val === undefined || val === null || val === '') continue;
          
          elements.push({val: String(val).toLowerCase(), weight: propDefs.length - i});
        }
        
        return {part, elements};
      })
    );
    
    const searchTerm = searchStr.trim().toLowerCase();
    const searchResults: {part: Part; weight: number}[] = [];
    
    if (searchTerm.length === 0) {
      return (
        searchItems
        .map(searchItem => ({part: searchItem.part}))
        .sort((a, b) => compareParts(a.part, b.part))
      );
    }
    
    for (const searchItem of searchItems) {
      let maxWeight: number | undefined;
      for (const element of searchItem.elements) {
        const index = element.val.indexOf(searchTerm);
        if (index > -1) {
          if (maxWeight === undefined || element.weight > maxWeight) {
            maxWeight = element.weight;
          }
        }
      }
      
      if (maxWeight !== undefined) {
        searchResults.push({
          part: searchItem.part,
          weight: maxWeight
        });
      }
    }
    
    searchResults.sort((a, b) =>
      (b.weight - a.weight) ||
      compareParts(a.part, b.part)
    );
    
    return searchResults;
  }, [searchStr, targetPartClassDef, parts]);
  
  const columnDefs = (targetPartClassDef?.propDefs || []).map(propDef => ({
    propDef
  }));
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={() => onClose()}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg my-8 w-5/6 p-6 text-left overflow-hidden shadow-xl transform transition-all">
              <div className="flex flex-col-reverse sm:flex-row">
                <div className="flex-1 relative rounded-md shadow-sm w-full group items-center mr-4">
                  <input
                    ref={inputRef}
                    type="text"
                    className="block w-full focus:ring-orange-500 focus:border-orange-500 py-2 pl-2 pr-10 border border-gray-300 rounded-md"
                    placeholder="Search for parts..."
                    value={searchStr}
                    onChange={event => setSearchStr(event.target.value)}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <button className="group text-gray-400">
                      <HeroIcons.SearchIcon className="h-5 w-5 group-hover:hidden" aria-hidden="true" />
                      <HeroIcons.XIcon className="h-5 w-5 hover:text-gray-600 hidden group-hover:block" aria-hidden="true"
                        onClick={() => {
                          if (inputRef.current) {
                            inputRef.current.value = '';
                            setSearchStr('');
                          }
                        }}
                      />
                    </button>
                  </div>
                </div>
                <div className="flex-none text-right mb-2 sm:mb-0">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="overflow-y-auto mt-4 h-96">
                <table className="w-full divide-y divide-gray-200 text-left">
                  <thead className="sticky top-0 overflow-hidden bg-gray-50 shadow border-b border-gray-200 sm:rounded-lg text-xs text-gray-500 uppercase tracking-wider">
                    <tr>
                      <th scope="col" className="px-2 w-0"><span className="w-6 h-6 inline-block" /></th>
                      {columnDefs.map(columnDef => (
                        <th key={columnDef.propDef.key} scope="col" className="px-2 py-2 text-xs">{columnDef.propDef.label}</th>
                      ))}
                    </tr>
                  </thead>  
                  <tbody className="text-sm text-gray-900">
                    {searchResults.length === 0? (
                      <tr className="bg-white">
                        <td className="px-2 py-2" colSpan={columnDefs.length + 1}>
                          No results found.
                        </td>
                      </tr>
                    ) : searchResults.map((searchResult, i) => {
                      const part = searchResult.part;
                      return (
                        <tr
                          key={part.id}
                          className={classNames(i % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer')}
                          onClick={() => targetFlowNode && onPartSelected(targetFlowNode, part)}
                        >
                          <td className="px-2 text-orange-500">
                            <button className="invisible group-hover:visible">
                              <HeroIcons.DownloadIcon className="w-6 h-6 inline-block" />
                            </button>
                          </td>
                          {columnDefs.map(columnDef => (
                            <td key={`${part.id}.${columnDef.propDef.key}`} className="px-2 py-2">
                              {formatPartPropValue(part.propDict[columnDef.propDef.key], columnDef.propDef.format)}
                            </td>
                          ))}
                          
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function compareParts(a: Part, b: Part) {
  return (
    String(a.propDict['Manufacturer']).localeCompare(String(b.propDict['Manufacturer'])) ||
    String(a.propDict['Vendor Part Number']).localeCompare(String(b.propDict['Vendor Part Number']))
  );
}

const usdNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});
const defaultNumberFormat = new Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  minimumSignificantDigits: 1
});
function formatPartPropValue(val: unknown, format: PartClassPropFormat) {
  if (val === undefined || val === null || val === '') {
    return '-';
  }
  switch (format) {
    case 'URL':
      return (<a href={val as string} target="_blank" rel="noopener noreferrer" className="text-orange-500 underline" >Link</a>);
    case 'USD':
      if (typeof val !== 'number') return String(val);
      return usdNumberFormat.format(val);
    case 'number':
      if (typeof val !== 'number') return String(val);
      return defaultNumberFormat.format(val);
    case 'boolean':
      if (typeof val !== 'boolean') return String(val);
      return val? 'Yes' : 'No';
    default:
      return String(val);
  }
}