import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {AuthModelMode} from './authModal/AuthModal';

export interface AuthUser {
  id: string;
  name: string;
  email: string;
  authToken: string;
  isAdmin: boolean;
}

const AuthContainer = createContainer(() => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authModalMode, setAuthModalMode] = useState(AuthModelMode.LOGIN);
  const [initalEmail, setInitalEmail] = useState<string>();
  const [authUser, setAuthUser] = useState(getStoredAuthUser());
  
  function setLoggedInAuthUser(newAuthUser: AuthUser) {
    setAuthUser(newAuthUser);
    storeAuthUser(newAuthUser);
  }
  function clearStoredAuthUser() {
    storeAuthUser(undefined);
  }
  function showAuthLoginModal(initalEmail?: string) {
    setInitalEmail(initalEmail);
    setAuthModalMode(AuthModelMode.LOGIN);
    setShowAuthModal(true);
  }
  function showAuthRegisterModal() {
    setAuthModalMode(AuthModelMode.REGISTER);
    setShowAuthModal(true);
  }
  function showAuthForgotPasswordModal(initalEmail?: string) {
    setInitalEmail(initalEmail);
    setAuthModalMode(AuthModelMode.FORGOT_PASSWORD);
    setShowAuthModal(true);
  }
  function showAuthEmailVerificationModal(initalEmail?: string) {
    setInitalEmail(initalEmail);
    setAuthModalMode(AuthModelMode.EMAIL_VERIFICATION);
    setShowAuthModal(true);
  }
  function hideAuthModal() {
    setShowAuthModal(false);
  }
  
  return {
    authUser,
    showAuthLoginModal,
    showAuthRegisterModal,
    showAuthForgotPasswordModal,
    showAuthEmailVerificationModal,
    hideAuthModal,
    setLoggedInAuthUser,
    clearStoredAuthUser,
    showAuthModal,
    authModalMode,
    initalEmail,
  };
});
export default AuthContainer;

const AUTH_USER_STORAGE_KEY = 'authUser';
function storeAuthUser(authUser: AuthUser | undefined) {
  if (authUser) {
    localStorage.setItem(AUTH_USER_STORAGE_KEY, JSON.stringify(authUser));
  }
  else {
    localStorage.removeItem(AUTH_USER_STORAGE_KEY);
  }
}
function getStoredAuthUser(): AuthUser | undefined {
  const authUserJSON = localStorage.getItem(AUTH_USER_STORAGE_KEY);
  if (!authUserJSON) return;
  return JSON.parse(authUserJSON);
}