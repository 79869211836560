import config from '../config';
import React, {useEffect, useState} from 'react';
import AuthContainer from '../components/AuthContainer';
import {PartClassDef} from '../partClasses/partClassDef';
import {partClassDefDict} from '../partClasses/allPartClassDefs';
import {classNames} from '../utils/classNames';
import ErrorHandlerContainer from '../components/ErrorHandlerContainer';

export default function ContactPage() {
  const {handleError} = ErrorHandlerContainer.useContainer();
  const {authUser} = AuthContainer.useContainer();
  const [partClassDef, setPartClassDef] = useState<PartClassDef>();
  const [procState, setProcState] = useState({
    isLoading: false,
    wasSuccessful: false,
    message: undefined as string | JSX.Element | undefined
  });
  
  const [formData, setFormData] = useState({
    stateDict: {} as Record<string, File | undefined>,
    portDict: {} as Record<string, string>,
    propDict: {} as Record<string, string>,
  });
  
  useEffect(() => {
    setFormData({
      stateDict: {},
      propDict: {},
      portDict: Object.fromEntries(partClassDef?.handleDefs.map((handleDef, i) =>
        [handleDef.portKey, i.toString()]
      ) || []),
    });
  }, [partClassDef]);
  
  if (!authUser) return (<div>401 - Must be logged in.</div>);
  if (!authUser.isAdmin) return (<div>403 - Insufficient permissions.</div>);
  
  function onSubmit(event?: React.FormEvent) {
    event?.preventDefault();
    
    if (procState?.isLoading) return;
    setProcState({
      isLoading: true,
      wasSuccessful: false,
      message: undefined
    });
    
    processForm()
    .then(errorMessage => {
      setProcState({
        isLoading: false,
        wasSuccessful: !errorMessage,
        message: errorMessage,
      });
    })
    .catch(err => {
      handleError(err);
      setProcState({
        isLoading: false,
        wasSuccessful: false,
        message: 'An error occurred.',
      });
    });
    
    async function processForm(): Promise<string | JSX.Element | undefined> {
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      if (!partClassDef) {
        return 'Must select part class.';
      }
      
      const reqFormData = new FormData();
      
      const reqStateDict: Record<string, string> = {};
      for (const state of (partClassDef.states || [{key: 'SINGULAR', label: ''}])) {
        const touchstoneFile = formData.stateDict[state.key];
        if (!touchstoneFile) return `Must set touchstone file${state.label? ` for ${state.label}` : ''}.`;
        
        const fileFieldname = `touchstone-file-${state.key}`;
        reqStateDict[state.key] = fileFieldname;
        reqFormData.set(fileFieldname, touchstoneFile, touchstoneFile.name);
      }
      
      const reqPortDict: Record<string, number> = {};
      for (const handleDef of partClassDef.handleDefs) {
        const touchstonePortNum = parseInt(formData.portDict[handleDef.portKey], 10);
        if (isNaN(touchstonePortNum)) return `Port ${handleDef.label} must be mapped to a touchstone port number.`;
        reqPortDict[handleDef.portKey] = touchstonePortNum;
      }
      
      const reqPropDict: Record<string, string> = {};
      for (const propDef of (partClassDef.propDefs || [])) {
        const val = formData.propDict[propDef.key];
        reqPropDict[propDef.key] = val;
      }
      
      reqFormData.set('json', JSON.stringify({
        partClass: partClassDef.partClass,
        stateDict: reqStateDict,
        portDict: reqPortDict,
        propDict: reqPropDict,
        isInProteusCatalog: true
      }));
      
      const res = await fetch(`${config.apiURL}/createPart`, {
        method: 'POST',
        headers: {
          'Authorization': authUser? `Bearer ${authUser?.authToken}` : ''
        },
        body: reqFormData
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        throw new Error(`Non-2XX response when fetching addPart: ${res.status}\n${bodyStr}`);
      }
      
      return;
    }
  }
  
  return (
    <div>
      <header>
        <h1 className="text-3xl font-bold leading-tight text-gray-900">Add Part</h1>
      </header>
      <main className="pt-10">
        
        {procState.wasSuccessful? (
          <div>
            Success!
          </div>
        ): <>
        
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            
            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="part-title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Title
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input type="text" name="part-title" id="part-title" className="max-w-lg block w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div> */}
            
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="part-class" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Class
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select id="part-class" name="part-class" className="max-w-lg block focus:ring-orange-500 focus:border-orange-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" required
                  value={partClassDef?.partClass || ''}
                  onChange={event => setPartClassDef(partClassDefDict[event.target.value])}
                >
                  <option value="">Select Part Class</option>
                  {Object.values(partClassDefDict)
                  .filter(partClassDef => !partClassDef.hasNoPart)
                  .map(({partClass}) => (
                    <option key={partClass} value={partClass}>{partClass}</option>
                  ))}
                </select>
              </div>
            </div>
            
            {partClassDef? <>
            
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="touchstone-file:SINGULAR" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Touchstone Files
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start" style={{gridTemplateColumns: 'min-content auto'}}>
                  {(partClassDef.states || [{key: 'SINGULAR', label: ''}]).map((partClassState, i) => <>
                    <label key={`label${i}`} htmlFor={`touchstone-file:${partClassState.key}`} className="whitespace-nowrap">
                      {partClassState.label}
                    </label>
                    <div key={`input${i}`} className="overflow-hidden">
                      <input id={`touchstone-file:${partClassState.key}`} name={`touchstone-file:${partClassState.key}`} type="file" required
                        key={
                          // Including partClass as part of the key causes the input to be replaced
                          // when changing part class which prevents bugs releated to file inputs not
                          // able to be a controlled component.
                          `file-${partClassDef.partClass}-${i}`
                        }
                        onChange={event => setFormData(formData => ({...formData,
                          stateDict: {...formData.stateDict,
                            [partClassState.key]: event.target.files?.[0]
                          }
                        }))}
                      />
                    </div>
                  </>)}
                </div>
              </div>
            </div>
            
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Touchstone Ports
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start" style={{gridTemplateColumns: 'min-content auto'}}>
                  {partClassDef.handleDefs.map((handleDef, i) => <>
                    <label key={`label${i}`} htmlFor={`port-mapping:${handleDef.portKey}`} className="whitespace-nowrap">
                      {handleDef.label}
                    </label>
                    <div key={`input${i}`} className="overflow-hidden">
                      <input type="number" id={`port-mapping:${handleDef.portKey}`} name={`port-mapping:${handleDef.portKey}`} min="0" step="1" className="max-w-lg block w-24 shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" required
                        value={formData.portDict[handleDef.portKey] || ''}
                        onChange={event => setFormData(formData => ({...formData,
                          portDict: {...formData.portDict,
                            [handleDef.portKey]: event.target.value
                          }
                        }))}
                      />
                    </div>
                  </>)}
                </div>
              </div>
            </div>
            
            {(partClassDef.propDefs || []).map(propDef =>
              <div key={`prop-${propDef.key}`} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor={`prop-${propDef.key}`} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {propDef.label}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type={propDef.format === 'number' || propDef.format === 'USD'? 'number' : propDef.format === 'boolean'? 'checkbox' : 'text'}
                    name={`prop:${propDef.key}`}
                    id={`prop:${propDef.key}`}
                    className={classNames(propDef.format !== 'boolean'? 'max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md' : '', 'focus:ring-orange-500 focus:border-orange-500')}
                    required={propDef.key === 'Manufacturer' || propDef.key === 'Vendor Part Number'}
                    value={formData.propDict[propDef.key] || ''}
                    checked={formData.propDict[propDef.key] === 'TRUE'}
                    onChange={event => setFormData(formData => ({...formData,
                      propDict: {...formData.propDict,
                        [propDef.key]: (
                          propDef.format === 'boolean'
                          ? (event.target.checked? 'TRUE' : 'FALSE')
                          : event.target.value
                        )
                      }
                    }))}
                  />
                </div>
              </div>
            )}
            
            </> : <></>}
          </div>
          
          <div className="mt-2">
            <button
              type="submit"
              disabled={procState.isLoading}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              {procState.isLoading? (<>
                Submitting
                <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </>) : (<>
                Submit
              </>)}
            </button>
          </div>
          
          <div className={classNames(
            !procState.message? 'hidden' : '',
            'bg-red-50 p-2 border text-red-600 border-red-400'
          )}>
            {procState.message}
          </div>
          
        </form>
        
        </>}
        
      </main>
    </div>
  );
}