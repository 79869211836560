import {useState} from 'react';
import config from '../../config';
import {fetchAuthUser} from '../../utils/authHelper';
import {classNames} from '../../utils/classNames';
import {clientHashPassword} from '../../utils/cryptic';
import AuthContainer from '../AuthContainer';
import {AuthFormProcState, AuthFormProps} from './AuthModal';
import {LinkButton} from '../LinkButton';

export function LoginForm({handleFormSubmit}: AuthFormProps) {
  const {initalEmail, setLoggedInAuthUser, showAuthRegisterModal, showAuthForgotPasswordModal, showAuthEmailVerificationModal} = AuthContainer.useContainer();
  const [procState, setProcState] = useState<AuthFormProcState>({});
  const [email, setEmail] = useState(initalEmail || '');
  const [password, setPassword] = useState('');
  
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    handleFormSubmit({event, procState, setProcState}, async () => {
      if (!email || !password) {
        return {message: 'Missing data.'};
      }
      
      const passwordClientHashBase64 = await clientHashPassword(password);
      
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      
      const res = await fetch(`${config.apiURL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          passwordClientHashBase64
        })
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        let bodyObj;
        try {
          bodyObj = JSON.parse(bodyStr) as {
            errors?: {
              message: string;
              code?: string;
              details?: string;
            }[];
          };
        } catch(err) {/*noop*/}
        
        switch (bodyObj?.errors?.[0].code) {
          case 'INVALID_EMAIL': return {message: (<>
            Your email is not in our system. If you don't have an account, you
            can <LinkButton onClick={() => showAuthRegisterModal()}>create one</LinkButton>.
          </>)};
          case 'INVALID_PASSWORD': return {message: (<>
            Your password is incorrect. If you forgot your password, you
            can <LinkButton onClick={() => showAuthForgotPasswordModal(email)}>reset it</LinkButton>.
          </>)};
          case 'UNVERIFIED_EMAIL': return {message: (<>
            Your email address has not yet been verified. Please check your inbox and junk mail for
            the verification email. If you did not get one,
            try <LinkButton onClick={() => showAuthEmailVerificationModal(email)}>sending another one</LinkButton>.
          </>)};
        }
        throw new Error(`Non-2XX response when fetching login: ${res.status}\n${bodyStr}`);
      }
      
      const {userID, jwt} = (await res.json()) as {userID: string; jwt: string};
      
      const authUser = await fetchAuthUser(userID, jwt);
      setLoggedInAuthUser(authUser);
      
      return {
        wasSuccessful: true,
        shouldClose: true
      };
    });
  }
  
  return (
     <form className="space-y-6" onSubmit={onSubmit}>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email address
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </div>
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        {/* <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            defaultChecked
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
            Remember me
          </label>
        </div> */}
        
        <div className="text-sm">
          <LinkButton onClick={() => showAuthRegisterModal()}>Create an Account</LinkButton>
        </div>
        
        <div className="text-sm">
          <LinkButton onClick={() => showAuthForgotPasswordModal()}>Forgot your password?</LinkButton>
        </div>
      </div>
      
      <div>
        <button
          type="submit"
          disabled={procState.isLoading}
          className={classNames('w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2', procState.isLoading? 'bg-gray-300 focus:ring-gray-300' : 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500')}
        >
          {procState.isLoading? (<>
            Signing in
            <svg className="animate-spin ml-2 -mr-1 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </>) : (<>
            Sign in
          </>)}
        </button>
      </div>
    
      <div className={classNames(
        !procState.message? 'hidden' : '',
        'bg-red-50 p-2 border text-red-600 border-red-400'
      )}>
        {procState.message}
      </div>
    </form>
  );
}