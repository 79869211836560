import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import * as HeroIcons from '@heroicons/react/outline';

export default function ShareModel({diagramID, show, onClose}: {
  diagramID: string;
  show: boolean;
  onClose: () => void;
}) {
  const [animateCopyButtonTimer, setAnimateCopyButtonTimer] = useState<number>();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  
  useEffect(() => {
    if (!show && animateCopyButtonTimer) {
      clearTimeout(animateCopyButtonTimer);
      setAnimateCopyButtonTimer(undefined);
    }
  }, [show, animateCopyButtonTimer]);
  
  const shareURL = useMemo(() => {
    const url = new URL(window.location.origin);
    url.searchParams.set('diagramID', diagramID);
    return url.href;
  }, [diagramID]);
  
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={() => onClose()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <HeroIcons.ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div> */}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Share Link
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-base text-gray-500">
                      Use the link bellow to share this diagram. Note that only you can make changes
                      to this diagram.
                    </p>
                  </div>
                </div>
              </div>
              <textarea
                ref={textareaRef}
                readOnly
                className="w-full font-mono text-xs mt-4 h-32 p-1 border border-black outline-none resize-y"
                value={shareURL}
              />
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-between">
                <button
                  type="button"
                  className="w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                  onClick={() => {
                    if (!textareaRef.current) return;
                    textareaRef.current.select();
                    textareaRef.current.setSelectionRange(0, 99999);
                    void navigator.clipboard.writeText(textareaRef.current.value);
                    
                    if (animateCopyButtonTimer !== undefined) {
                      clearTimeout(animateCopyButtonTimer);
                    }
                    setAnimateCopyButtonTimer((setTimeout as Window['setTimeout'])(() => {
                      setAnimateCopyButtonTimer(undefined);
                    }, 3000));
                  }}
                >
                  {animateCopyButtonTimer !== undefined
                  ? (<><HeroIcons.ThumbUpIcon className="-ml-1 mr-2 h-5 w-5 -scale-x-100 bounce-6" aria-hidden="true" />Done!</>)
                  : (<><HeroIcons.ClipboardCopyIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />Copy</>)}
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm mt-3 sm:mt-0 "
                  onClick={() => onClose()}
                >
                  OK
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
