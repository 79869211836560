import config from '../config';

export function validatePassword(password: string) {
  if (password.length < 8) return 'Password must be at least 8 characters.';
}

export async function clientHashPassword(password: string) {
  const saltBuffer = config.passwordClientSaltBuffer;
  if (saltBuffer.byteLength === 0) {
    throw new Error(`Password client salt not configured.`);
  }
  
  const passwordBuffer = new TextEncoder().encode(password);
  
  const passwordClientHash = await crypto.subtle.digest('SHA-256', concatBuffers(passwordBuffer, saltBuffer));
  const passwordClientHashBase64 = window.btoa(String.fromCharCode(...Array.from(new Uint8Array(passwordClientHash))));
  
  return passwordClientHashBase64;
}

function concatBuffers(a: Uint8Array, b: Uint8Array) {
  const c = new Int8Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
}