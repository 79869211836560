import {FlowNodeData} from '../types';
import type * as ReactFlow from 'react-flow-renderer';

export interface PartClassDef {
  partClass: string;
  diagramIDPrefix: string;
  states?: PartClassState[];
  FlowNodeComponent: React.FC<{data: FlowNodeData}>;
  handleDefs: PartClassHandleDef[];
  hasNoPart?: boolean;
  propDefs?: PartClassPropDef[];
}
export interface PartClassHandleDef {
  portKey: string;
  label: string;
  initPosition: ReactFlow.Position;
  offsetPercent?: number;
}

export interface PartClassState {
  key: string;
  label: string;
}

export interface PartClassPropDef {
  key: string;
  label: string;
  format: PartClassPropFormat;
}
export type PartClassPropFormat = 'number' | 'string' | 'boolean' | 'URL' | 'USD';

export function createPartClassPropDefs(arr: {
  label: string;
  key?: string;
  format?: PartClassPropFormat;
}[]): PartClassPropDef[] {
  return arr.map(x => ({
    key: x.key || x.label,
    label: x.label,
    format: x.format || 'string',
  }));
}