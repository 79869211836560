import * as HeroIcons from '@heroicons/react/outline';
import {classNames} from '../../utils/classNames';
import './AnalysisTab.css';
import * as Plotly from 'plotly.js';
import Plot from 'react-plotly.js';
import {useState, useEffect} from 'react';
import {createContainer} from 'unstated-next';
import config from '../../config';
import {overlapObjects} from '../../utils/objectHelper';
import {DiagramData, FlowNodeDataSerialized} from '../../types';
import {ValidateAnalysisData} from './../../components/ValidateAnalysisData';

interface AnalysisResult {
  networks: AnalysisNetworkResult[];
}
interface AnalysisNetworkResult {
  key: string;
  title: string;
  data: AnalysisNetworkData;
}

interface AnalysisData {
  networks: AnalysisNetworkData[];
}
interface AnalysisNetworkData {
  fromNodeID: string;
  toNodeID: string;
  name: string;
  _frequency: {
    funit: string;
    flist: number[];
  };
  _s: number[][][][];
}

export const AnalysisDataContainer = createContainer(() => {
  const [analysisInProgress, setAnalysisInProgress] = useState(false);
  const [analysisErrorInfo, _setAnalysisErrorInfo] = useState<{
    message: string;
    subMessages?: string[];
  }>();
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult>();
  
  function showError(message: string, subMessages?: string[]) {
    setAnalysisResult(undefined);
    _setAnalysisErrorInfo({message, subMessages});
  }
  
  async function analyze(diagramData: DiagramData | undefined) {
    if (analysisInProgress) return;
    setAnalysisInProgress(true);
    _setAnalysisErrorInfo(undefined);
    
    try {
      if (!diagramData) {
        throw new Error(`Diagram data is empty.`);
      }
      
      //moved analysis data validation to seperate function to call in multiple locations
      const diagramErrorMessages = ValidateAnalysisData(diagramData);
      
      if (diagramErrorMessages.length > 0) {
        showError('Error with design:', diagramErrorMessages);
        return;
      }
      
      if (!config.apiURL) {
        throw new Error(`API URL not configured.`);
      }
      
      console.log('Analyzing...');
      const res = await fetch(`${config.apiURL}/analyzeDiagram`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        // eslint-disable-next-line quotes
        body: JSON.stringify({diagramData})
      });
      if (!res.ok) {
        const bodyStr = await res.text();
        throw new Error(`Non-2XX response when fetching analysis: ${res.status}\n${bodyStr}`);
      }
      
      const analysisData: AnalysisData = (await res.json()).analysisData;
      const analysisResult: AnalysisResult = {
        networks: analysisData.networks.map((networkData, i) => ({
          key: buildAnalysisNetworkKey(networkData),
          title: buildAnalysisNetworkTitle(networkData, diagramData),
          data: networkData
        }))
      };
      
      setAnalysisResult(analysisResult);
      console.log('Done analyzing.');
    }
    catch(err) {
      showError('An error occured.');
      throw err;
    }
    finally {
      setAnalysisInProgress(false);
    }
  }
  
  return {
    analysisInProgress,
    analysisErrorInfo,
    analysisResult,
    analyze,
  };
});

export default function AnalysisTab({show}: {show: boolean}) {
  const {analysisInProgress, analysisErrorInfo, analysisResult} = AnalysisDataContainer.useContainer();
  const [selectedNetworkResult, setSelectedNetworkResult] = useState<AnalysisNetworkResult>();
  const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>([]);
  const [plotlyLayoutPartial, setPlotlyLayoutPartial] = useState<Partial<Plotly.Layout>>({});
  const [plotlyState, setPlotlyState] = useState<{
    layout: Partial<Plotly.Layout>;
    frames: Plotly.Frame[] | null;
    config: Partial<Plotly.Config>;
    revision: number;
  }>({
    layout: {
      title: 'Typical',
      autosize: true,
      xaxis: {
        title: 'GHz',
        showgrid: true,
        zeroline: true,
      },
      yaxis: {
        title: 'Magnitude (dB)',
        showgrid: true,
        zeroline: false
      }
    },
    frames: [],
    config: {
      responsive: true,
    },
    revision: 1
  });
  
  useEffect(() => {
    if (!analysisResult) return;
    
    if (!selectedNetworkResult) {
      setSelectedNetworkResult(analysisResult.networks[0]);
    }
    else if (!analysisResult.networks.includes(selectedNetworkResult)) {
      setSelectedNetworkResult(
        analysisResult.networks.find(x => x.key === selectedNetworkResult.key) ||
        analysisResult.networks[0]
      );
    }
  }, [analysisResult, selectedNetworkResult]);
  
  useEffect(() => {
    if (!selectedNetworkResult) {
      setPlotlyData([]);
      setPlotlyLayoutPartial({});
    }
    else {
      setPlotlyData(convertAnalysisNetworkDataToPlotlyData(selectedNetworkResult.data));
      setPlotlyLayoutPartial(convertAnalysisNetworkResultToPlotlyLayout(selectedNetworkResult));
    }
  }, [selectedNetworkResult]);
  
  return (
    <div className={classNames('w-full h-full relative', show? '' : 'hidden')} style={{minHeight: '24rem'}}>
      <div className={classNames(analysisResult? '' : 'hidden', 'w-full h-full flex flex-col')}>
        <div className="flex-none">
          <select
            value={selectedNetworkResult?.key}
            onChange={event => {
              const key = event.target.value;
              const networkResult = analysisResult?.networks.find(x => x.key === key);
              if (networkResult) {
                setSelectedNetworkResult(networkResult);
              }
            }}
          >
            {analysisResult?.networks.map(x => (
              <option key={x.key} value={x.key}>{x.title}</option>
            ))}
          </select>
        </div>
        
        <div className="flex-1 h-full">
          <Plot
            data={plotlyData}
            layout={
              plotlyLayoutPartial
              ? overlapObjects(plotlyState.layout, plotlyLayoutPartial)
              : plotlyState.layout
            }
            frames={plotlyState.frames ?? undefined}
            config ={{responsive: true,displaylogo: false,modeBarButtonsToRemove: ['resetScale2d','zoomIn2d','zoomOut2d','pan2d']}}
            useResizeHandler
            style={{width: '100%', height: '100%', overflow: 'hidden'}}
            onInitialized={figure => setPlotlyState(x => Object.assign(x, figure))}
            onUpdate={figure => setPlotlyState(x => Object.assign(x, figure))}
            revision={plotlyState.revision}
          />
        </div>
      </div>
      {analysisInProgress? (
        <div className="absolute inset-0 bg-gray-300 text-xl bg-opacity-75 p-2 flex justify-center items-center">
          <div>
            Analyzing...
            <svg className="animate-spin ml-2 -mr-1 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      ) : analysisErrorInfo? (
        <div className="w-full h-full bg-red-50 text-red-600 text-xl p-2 flex justify-center items-center">
          <div>
            <p>{analysisErrorInfo.message}</p>
            {analysisErrorInfo.subMessages && analysisErrorInfo.subMessages.length > 0? (
              <ul className="list-inside list-disc">
                {analysisErrorInfo.subMessages.map(msg => (<li>{msg}</li>))}
              </ul>
            ): <></>}
          </div>
        </div>
      ) : !analysisResult? (
        <div className="w-full h-full flex justify-end text-gray-500">
          <div className="flex-inital text-center pt-16 pl-4">
            Click the Analyze button any time to see the results.
          </div>
          <div className="flex-none pt-3 pr-28">
            <HeroIcons.ArrowUpIcon className="w-16 h-16 inline-block align-text-bottom text-orange-500 ml-2 slide-fwd-top" />
          </div>
        </div>
      ): <></>}
    </div>
  );
}

function buildAnalysisNetworkKey(analysisNetworkData: AnalysisNetworkData) {
  return `${analysisNetworkData.fromNodeID}-${analysisNetworkData.toNodeID}`;
}
function buildAnalysisNetworkTitle(
  analysisNetworkData: AnalysisNetworkData,
  analyzedDiagramData: DiagramData
) {
  const fromNode = analyzedDiagramData.flowData.elements.find(x => x.id === analysisNetworkData.fromNodeID);
  const toNode   = analyzedDiagramData.flowData.elements.find(x => x.id === analysisNetworkData.toNodeID);
  
  const fromNodeDiagramID = (fromNode?.data as FlowNodeDataSerialized)?.diagramID;
  const toNodeDiagramID   = (toNode?.data   as FlowNodeDataSerialized)?.diagramID;
  
  if (!fromNodeDiagramID || !toNodeDiagramID) {
    return analysisNetworkData.name || '???';
  }
  return `${fromNodeDiagramID} to ${toNodeDiagramID}`;
}

function convertAnalysisNetworkDataToPlotlyData(analysisNetworkData: AnalysisNetworkData): Plotly.Data[] {
  const traces: Partial<Plotly.PlotData>[] = [];
  
  const w = 2;
  const h = 2;
  for (let y = 0; y < h; ++y) {
    for (let x = 0; x < w; ++x) {
      traces[(y*w) + x] = {
        x: analysisNetworkData._frequency.flist,
        y: [],
        mode: 'lines',
        name: `S${x+1}${y+1}`
      };
    }
  }
  
  for (const item of analysisNetworkData['_s']) {
    for (let y = 0; y < h; ++y) {
      for (let x = 0; x < w; ++x) {
        (traces[(y*w) + x].y as number[]).push(magSumSq(item[x][y]));
      }
    }
  }
  
  return traces;
}

function convertAnalysisNetworkResultToPlotlyLayout(
  analysisNetworkResult: AnalysisNetworkResult,
): Partial<Plotly.Layout> {
  return {
    title: analysisNetworkResult.title,
    xaxis: {
      title: analysisNetworkResult.data._frequency.funit,
    },
    yaxis: {
      title: 'Magnitude (dB)',
    }
  };
}

function magSumSq(numbers: number[]): number {
  let sum = 0;
  for (let i = 0; i < numbers.length; i++) {
    sum += numbers[i]**2;
  }
  return 20*Math.log10(Math.sqrt(sum));
}
