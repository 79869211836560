import {createPartClassPropDefs, PartClassPropDef} from './partClassDef';

export const switchPropDefs: PartClassPropDef[] = createPartClassPropDefs([
  {label: 'Manufacturer'},
  {label: 'Vendor Part Number'},
  {label: 'HM Part Number'},
  // {label: 'Description'},
  {label: 'Low Frequency (GHz)', format: 'number'},
  {label: 'High Frequency (GHz)', format: 'number'},
  {label: 'Connector Type'},
  {label: 'Terminated?', format: 'boolean'},
  {label: 'Max VSWR'},
  {label: 'MAX Insertion Loss (dB)', format: 'number'},
  {label: 'Isolation Min (dB)', format: 'number'},
  {label: 'Life Time (Cycles, millions)', format: 'number'},
  {label: 'Actuator'},
  {label: 'Switching Time (us)', format: 'number'},
  {label: 'Average Power (W)', format: 'number'},
  // {label: 'Price', format: 'USD'},
  // {label: 'Lead Time (weeks)', format: 'number'},
  {label: 'Datasheet URL', format: 'URL'},
  // {label: 'Operating Temperature Range'},
]);