import * as ReactFlow from 'react-flow-renderer';
import {PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as XFERIcon1} from './icons/XFER_1.svg';
import {ReactComponent as XFERIcon2} from './icons/XFER_2.svg';
import './XFER.css';

const stateDict: Record<string, {label: string; icon: typeof XFERIcon1}> = {
  'conn0': {label: 'Output 1', icon: XFERIcon1},
  'conn1': {label: 'Output 2', icon: XFERIcon2},
};
const states = Object.entries(stateDict).map(([key, {label}]) => ({key, label}));

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: '1', initPosition: ReactFlow.Position.Left,  offsetPercent: 25},
  {portKey: 'input1',  label: '4', initPosition: ReactFlow.Position.Left,  offsetPercent: 75},
  {portKey: 'output0', label: '2', initPosition: ReactFlow.Position.Right, offsetPercent: 25},
  {portKey: 'output1', label: '3', initPosition: ReactFlow.Position.Right, offsetPercent: 75},
];

const partClassDef: PartClassDef = {
  partClass: 'XFER',
  diagramIDPrefix: 'SW',
  states,
  handleDefs,
  FlowNodeComponent: ({data}) => {
    const stateKey = data.stateKey || states[0].key;
    const Icon = stateDict[stateKey].icon;
    return (
      <div className={`flow-node xfer-node state-${stateKey} ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <Icon />
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        {/* <div className="label">{data.label}</div> */}
      </div>
    );
  }
};
export default partClassDef;
