import {Transition, Dialog} from '@headlessui/react';
import {Fragment} from 'react';
import './InfoModal.css';
import {ReactComponent as SourceIcon} from './../../partClasses/Source/icons/Source_R.svg';
import {ReactComponent as LoadIcon} from './../../partClasses/Load/icons/Load_L.svg';

export default function InfoModel({show, onClose} : {
  show: boolean;
  onClose: () => void;
}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={() => onClose()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <HeroIcons.ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div> */}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                     Getting Started, Usage, and Tips
                    </Dialog.Title>
                    <div className="mt-2">
                      <ul className="text-base text-gray-500 list-disc">
                        <li> <text className = "inlineIcon">All Signals flow from  <SourceIcon/>(Source) to <LoadIcon/> (Node).</text></li>
                        <li>All results are displayed as two port measurements. All other paths are assumed to be terminated.</li>
                        <li>All components must have a part to conduct an Analysis. Part info can be found below the part selection section on the right side of the screen.</li>
                        <li>All components that do not have a part have a <text className = "noPartText">Red Glow</text> when not selected.</li>
                        <li>To connect components, click and drag your mouse between the two ports you wish to connect.</li>
                        <li>Right click on a part or connection to show all possible options and their corresponding <kbd>hotkeys</kbd>.</li>
                        <li>Designs can get cluttered - you can toggle sheet references to clean up some connections.</li>
                        <li>The analysis will show all possible two port connections based on the topography of your circuit.</li>
                        <li>You must sign in or register to save or share designs</li>
                        <li>There is a complex interplay between each part in a circuit. You may see different graphs in a circuit cover different frequency ranges. 
                            That is because we take care some things under the hood to ensure you see the overall performance of each path.</li>
                      </ul>
                      <p className="font-bold">We deeply value feedback. Please contact us if you have questions, concerns, or ideas for improvement!</p>
                    </div>
                    <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => onClose()}
                  >
                    Got It
                  </button>
                  </div>
                </div>
             </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}