import React from 'react';
import * as ReactFlow from 'react-flow-renderer';
import {createPartClassPropDefs, PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as CableIcon} from './icons/Cable_1.svg';
import './Cable.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: '1', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: '2', initPosition: ReactFlow.Position.Right}
];

const partClassDef: PartClassDef = {
  partClass: 'Cable',
  diagramIDPrefix: 'W',
  handleDefs,
  propDefs: createPartClassPropDefs([
    {label: 'Manufacturer'},
    {label: 'Vendor Part Number'},
    {label: 'HM Part Number'},
    //{label: 'Description'},
    //{label: 'Low Frequency (GHz)', format: 'number'},
    {label: 'High Frequency (GHz)', format: 'number'},
    {label: 'Connector Type'},
    {label: 'Length (in)', format: 'number'},
    {label: 'Max VSWR'},
    {label: 'MAX Insertion Loss (dB)', format: 'number'},
    {label: 'Loss Per Ft (db/FT)', format: 'number'},
    {label: 'Cable Diameter (in)', format: 'number'},
    {label: 'Bend Radius (in)', format: 'number'},
    //{label: 'Average Power (W)', format: 'number'},
    //{label: 'Price', format: 'USD'},
    //{label: 'Lead Time (weeks)', format: 'number'},
    //{label: 'Datasheet URL', format: 'URL'},
    //{label: 'Operating Temperature Range'},
  ]),
  FlowNodeComponent: ({data}) => {
    const lengthAtr = data.part?.propDict['Length (in)'];
    const diamAtr = data.part?.propDict['Cable Diameter (in)'];
    
    let text = lengthAtr !== undefined? String(lengthAtr) + 'in' : '';
    if (diamAtr !== undefined) {
      if (text) text += ', ';
      text += String(diamAtr);
    }
    
    return (
      <div className={`flow-node cable-node ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <CableIcon/>
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        <div className="label">{text}</div>
      </div>
      );
  }
};
export default partClassDef;