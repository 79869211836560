import attenuatorPartClassDef from './Attenuator/Attenuator';
import bulkheadPartClassDef   from './Bulkhead/Bulkhead';
import cablePartClassDef      from './Cable/Cable';
import loadPartClassDef       from './Load/Load';
import sourcePartClassDef     from './Source/Source';
import spdtPartClassDef       from './SPDT/SPDT';
import sp4tPartClassDef       from './SP4T/SP4T';
import sp6tPartClassDef       from './SP6T/SP6T';
import sp8tPartClassDef       from './SP8T/SP8T';
import sp10tPartClassDef      from './SP10T/SP10T';
import sp12tPartClassDef      from './SP12T/SP12T';
import xferPartClassDef       from './XFER/XFER';
import unicouplerPartClassDef from './UnidirectionalCoupler/UnidirectionalCoupler';
import bicouplerPartClassDef  from './BidirectionalCoupler/BidirectionalCoupler';
import amplifierPartClassDef  from './Amplifier/Amplifier';
import splitterPartClassDef   from './Splitter/Splitter';
import filterPartCLassDef     from './Filter/Filter';

export const partClassDefs = [
  amplifierPartClassDef,
  attenuatorPartClassDef,
  bulkheadPartClassDef,
  cablePartClassDef,
  filterPartCLassDef,
  spdtPartClassDef,
  sp4tPartClassDef,
  sp6tPartClassDef,
  sp8tPartClassDef,
  sp10tPartClassDef,
  sp12tPartClassDef,
  xferPartClassDef,
  unicouplerPartClassDef,
  bicouplerPartClassDef,
  splitterPartClassDef,
  sourcePartClassDef,
  loadPartClassDef,
] as const;
export const partClassDefDict = Object.fromEntries(partClassDefs.map(x => [x.partClass, x]));

// Ensure no duplicates
for (let i = 1; i < partClassDefs.length; ++i) {
  for (let j = 0; j < i; ++j) {
    if (partClassDefs[j].partClass === partClassDefs[i].partClass) {
      throw new Error(`Duplicate partClass '${partClassDefs[j].partClass}' at indexes ${i} and ${j}.`);
    }
  }
}