import * as ReactFlow from 'react-flow-renderer';

const clockwisePositions = [
  ReactFlow.Position.Left,
  ReactFlow.Position.Top,
  ReactFlow.Position.Right,
  ReactFlow.Position.Bottom
];
const positionOffsetDefDict: Record<ReactFlow.Position, [string, boolean]> = {
  [ReactFlow.Position.Left  ]: ['top',  true ],
  [ReactFlow.Position.Top   ]: ['left', false],
  [ReactFlow.Position.Right ]: ['top',  false],
  [ReactFlow.Position.Bottom]: ['left', true ],
};

export function orientPosition(initPosition: ReactFlow.Position, orientation: number, offsetPercent?: number) {
  if (orientation < 0) {
    orientation = 4 + (orientation % 4);
  }
  const position = (
    !orientation
    ? initPosition
    : clockwisePositions[(clockwisePositions.indexOf(initPosition) + orientation) % 4]
  );
  
  return {
    position,
    style: offsetPercent === undefined? undefined : {
      [positionOffsetDefDict[position][0]]: `${positionOffsetDefDict[position][1] === positionOffsetDefDict[initPosition][1]? offsetPercent : 100 - offsetPercent}%`
    }
  };
}

export function getRotClassName(orientation: number) {
  if (orientation < 0) {
    orientation = 4 + (orientation % 4);
  }
  return `rot${(orientation || 0) % 4}`;
}