import * as ReactFlow from 'react-flow-renderer';
import {PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {switchPropDefs} from '../switchPropDefs';
import {ReactComponent as Sp12tIcon1} from './icons/SP12T_1.svg';
import {ReactComponent as Sp12tIcon2} from './icons/SP12T_2.svg';
import {ReactComponent as Sp12tIcon3} from './icons/SP12T_3.svg';
import {ReactComponent as Sp12tIcon4} from './icons/SP12T_4.svg';
import {ReactComponent as Sp12tIcon5} from './icons/SP12T_5.svg';
import {ReactComponent as Sp12tIcon6} from './icons/SP12T_6.svg';
import {ReactComponent as Sp12tIcon7} from './icons/SP12T_7.svg';
import {ReactComponent as Sp12tIcon8} from './icons/SP12T_8.svg';
import {ReactComponent as Sp12tIcon9} from './icons/SP12T_9.svg';
import {ReactComponent as Sp12tIcon10} from './icons/SP12T_10.svg';
import {ReactComponent as Sp12tIcon11} from './icons/SP12T_11.svg';
import {ReactComponent as Sp12tIcon12} from './icons/SP12T_12.svg';
import './SP12T.css';

const stateDict: Record<string, {label: string; icon: typeof Sp12tIcon1}> = {
  'conn0' : {label: 'Output 1',  icon: Sp12tIcon1},
  'conn1' : {label: 'Output 2',  icon: Sp12tIcon2},
  'conn2' : {label: 'Output 3',  icon: Sp12tIcon3},
  'conn3' : {label: 'Output 4',  icon: Sp12tIcon4},
  'conn4' : {label: 'Output 5',  icon: Sp12tIcon5},
  'conn5' : {label: 'Output 6',  icon: Sp12tIcon6},
  'conn6' : {label: 'Output 7',  icon: Sp12tIcon7},
  'conn7' : {label: 'Output 8',  icon: Sp12tIcon8},
  'conn8' : {label: 'Output 9',  icon: Sp12tIcon9},
  'conn9' : {label: 'Output 10', icon: Sp12tIcon10},
  'conn10': {label: 'Output 11', icon: Sp12tIcon11},
  'conn11': {label: 'Output 12', icon: Sp12tIcon12},
};
const states = Object.entries(stateDict).map(([key, {label}]) => ({key, label}));

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',   label:  'C', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0',  label:  '1', initPosition: ReactFlow.Position.Right, offsetPercent: -225},
  {portKey: 'output1',  label:  '2', initPosition: ReactFlow.Position.Right, offsetPercent: -175},
  {portKey: 'output2',  label:  '3', initPosition: ReactFlow.Position.Right, offsetPercent: -125},
  {portKey: 'output3',  label:  '4', initPosition: ReactFlow.Position.Right, offsetPercent:  -75},
  {portKey: 'output4',  label:  '5', initPosition: ReactFlow.Position.Right, offsetPercent:  -25},
  {portKey: 'output5',  label:  '6', initPosition: ReactFlow.Position.Right, offsetPercent:   25},
  {portKey: 'output6',  label:  '7', initPosition: ReactFlow.Position.Right, offsetPercent:   75},
  {portKey: 'output7',  label:  '8', initPosition: ReactFlow.Position.Right, offsetPercent:  125},
  {portKey: 'output8',  label:  '9', initPosition: ReactFlow.Position.Right, offsetPercent:  175},
  {portKey: 'output9',  label: '10', initPosition: ReactFlow.Position.Right, offsetPercent:  225},
  {portKey: 'output10', label: '11', initPosition: ReactFlow.Position.Right, offsetPercent:  275},
  {portKey: 'output11', label: '12', initPosition: ReactFlow.Position.Right, offsetPercent:  325},
];

const partClassDef: PartClassDef = {
  partClass: 'SP12T',
  diagramIDPrefix: 'SW',
  states,
  propDefs: switchPropDefs,
  handleDefs,
  FlowNodeComponent: ({data}) => {
    const stateKey = data.stateKey || states[0].key;
    const Icon = stateDict[stateKey].icon;
    return (
      <div className={`flow-node sp12t-node state-${stateKey} ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <Icon />
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        {/* <div className="label">{data.label}</div> */}
      </div>
    );
  }
};
export default partClassDef;
