import * as ReactFlow from 'react-flow-renderer';
import {AuthUser} from '../components/AuthContainer';
import config from '../config';
import {partClassDefDict} from '../partClasses/allPartClassDefs';
import {Diagram, DiagramData, FlowEdge, FlowEdgeData, FlowEdgeDataSerialized, FlowEdgeSerialized, FlowNode, FlowNodeData, FlowNodeDataSerialized, FlowNodeSerialized, Part, StashedDiagram} from '../types';
import {deepJSONObjEquals} from './objectHelper';

export async function fetchDiagram(diagramID: string, authUser?: AuthUser) {
  if (!config.apiURL) {
    throw new Error(`API URL not configured.`);
  }
  
  const url = new URL(`${config.apiURL}/getDiagram`);
  url.searchParams.set('diagramID', diagramID);
  const res = await fetch(url.href, {
    headers: authUser && {
      'Authorization': `Bearer ${authUser.authToken}`
    },
  });
  if (!res.ok) {
    const bodyStr = await res.text();
    let bodyObj;
    try {
      bodyObj = JSON.parse(bodyStr) as {
        errors?: {
          message: string;
          code?: string;
          details?: string;
        }[];
      };
    } catch(err) {/*noop*/}
    
    if (bodyObj?.errors?.[0].code === 'DOES_NOT_EXIST') {
      return;
    }
    
    throw new Error(`Non-2XX response when fetching diagram '${diagramID}': ${res.status}\n${bodyStr}`);
  }
  
  const {diagram} = (await res.json()) as {diagram: Diagram};
  return diagram;
}

export interface DiagramDef {
  id?: string;
  title: string;
  data: DiagramData;
}
export async function saveDiagramRemote(authUser: AuthUser, diagramDef: DiagramDef) {
  if (diagramDef.id) {
    await updateDiagramRemote(authUser, diagramDef.id, diagramDef);
    return diagramDef.id;
  }
  else {
    return await createDiagramRemote(authUser, diagramDef);
  }
}
export async function createDiagramRemote(authUser: AuthUser, diagramDef: {
  title: string;
  data: DiagramData;
}) {
  if (!config.apiURL) {
    throw new Error(`API URL not configured.`);
  }
  
  console.log(`Creating diagram...`);
  const url = new URL(`${config.apiURL}/createDiagram`);
  const res = await fetch(url.href, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${authUser.authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ownerUserID: authUser.id,
      title: diagramDef.title,
      data: diagramDef.data,
    })
  });
  if (!res.ok) {
    const bodyStr = await res.text();
    throw new Error(`Non-2XX response when fetching create-diagram: ${res.status}\n${bodyStr}`);
  }
  const {diagramID} = (await res.json()) as {diagramID: string};
  console.log(`Done creating diagram ${diagramID}.`);
  return diagramID;
}
export async function updateDiagramRemote(authUser: AuthUser, diagramID: string, diagramDef: {
  title: string;
  data: DiagramData;
}) {
  if (!config.apiURL) {
    throw new Error(`API URL not configured.`);
  }
  
  console.log(`Updating diagram ${diagramID}...`);
  const url = new URL(`${config.apiURL}/updateDiagram`);
  const res = await fetch(url.href, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${authUser.authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      diagramID,
      title: diagramDef.title,
      data: diagramDef.data,
    })
  });
  if (!res.ok) {
    const bodyStr = await res.text();
    throw new Error(`Non-2XX response when fetching update-diagram: ${res.status}\n${bodyStr}`);
  }
  console.log(`Done updating diagram.`);
}

export function compareDiagrams(a: {
  title: string;
  data: DiagramData;
}, b: {
  title: string;
  data: DiagramData;
}) {
  if (a.title !== b.title) return false;
  if (a.data.version !== b.data.version) return false;
  return deepJSONObjEquals(
    a.data.flowData.elements,
    b.data.flowData.elements
  );
}

export function serializeDiagramData(
  flowExport: ReactFlow.FlowExportObject<FlowNodeData | FlowEdgeData>
): DiagramData {
  return {
    version: 1,
    flowData: {
      ...flowExport,
      elements: flowExport.elements.map(element => {
        element = {...element};
        if (!element.data) return element;
        
        if (ReactFlow.isNode(element)) {
          const data = element.data as FlowNodeData;
          (element.data as unknown as FlowNodeDataSerialized) = {
            diagramIDInc: data.diagramIDInc,
            diagramID   : data.diagramID,
            orientation : data.orientation,
            label       : data.label,
            stateKey    : data.stateKey,
            partClass   : data.partClassDef.partClass,
            partID      : data.part?.id,
          };
        }
        else {
          const data = element.data as FlowEdgeData;
          (element.data as unknown as FlowEdgeDataSerialized) = {
            isTruncated: data.isTruncated,
          };
        }
        
        return element;
      }) as unknown as ReactFlow.Elements<FlowNodeDataSerialized | FlowEdgeDataSerialized>
    },
  };
}

export function deserializeDiagramData(diagramData: DiagramData, parts: Part[]): ReactFlow.FlowExportObject<FlowNodeData | FlowEdgeData> {
  if (diagramData.version !== 1) {
    throw new Error(`Unknown diagram version: ${diagramData.version}`);
  }
  
  // Create a copy of each element.
  const sElements = diagramData.flowData.elements.map(x => ({...x})) as (FlowNodeSerialized | FlowEdgeSerialized)[];
  const elements = sElements as (FlowNode | FlowEdge)[];
  
  for (const sElement of sElements) {
    if (ReactFlow.isNode(sElement)) {
      const node = sElement as unknown as FlowNode;
      const sNode = sElement;
      const sData = sNode.data;
      if (!sData) continue;
      
      const partClassDef = partClassDefDict[sData.partClass];
      if (!partClassDef) {
        throw new Error(`Unknown part class '${sData.partClass}' on node ${sNode.id}`);
      }
      
      let part: Part | undefined;
      if (sData.partID) {
        part = parts.find(x => x.id === sData.partID);
        if (!part) {
          console.warn(`Unknown part ID '${sData.partID}' on node ${sNode.id}`);
        }
      }
      
      node.data = {
        diagramIDInc: sData.diagramIDInc,
        diagramID   : sData.diagramID,
        orientation : sData.orientation,
        label       : sData.label,
        stateKey    : sData.stateKey,
        partClassDef,
        part,
      };
    }
    else {
      const edge = sElement as unknown as FlowEdge;
      const sEdge = sElement;
      const sData = sEdge.data;
      if (!sData) continue;
      
      const sourceFlowNode = sElements.find(x => x.id === sEdge.source);
      const targetFlowNode = sElements.find(x => x.id === sEdge.target);
      
      if (!sourceFlowNode) {
        throw new Error(`Unknown source node ID '${sEdge.source}' on edge ${sEdge.id}`);
      }
      if (!targetFlowNode) {
        throw new Error(`Unknown target node ID '${sEdge.target}' on edge ${sEdge.id}`);
      }
      
      edge.data = {
        isTruncated: sData.isTruncated,
        sourceFlowNode: sourceFlowNode as unknown as FlowNode,
        targetFlowNode: targetFlowNode as unknown as FlowNode,
      };
    }
  }
  
  return {
    ...diagramData.flowData,
    elements
  };
}

const STASHED_DIAGRAM_LOCAL_STORAGE_KEY = 'stashedDiagram2';
export function saveStashedDiagram(stashDiagram: StashedDiagram) {
  window.localStorage.setItem(STASHED_DIAGRAM_LOCAL_STORAGE_KEY, JSON.stringify(stashDiagram));
}
export function loadStashedDiagram() {
  const localJSON = window.localStorage.getItem(STASHED_DIAGRAM_LOCAL_STORAGE_KEY);
  if (!localJSON) {
    return;
  }
  
  let localVal: unknown;
  try {
    localVal = JSON.parse(localJSON);
  } catch(err) {
    console.error(`Local save state is not valid JSON.`, err);
    return;
  }
  
  if (
    typeof localVal !== 'object' ||
    !localVal ||
    !(localVal as StashedDiagram).data ||
    typeof (localVal as StashedDiagram).data !== 'object' ||
    typeof (localVal as StashedDiagram).data.version !== 'number'
  ) {
    console.error(`Local save state does not look valid.`);
    return;
  }
  
  const localSaveState = localVal as StashedDiagram;
  return localSaveState;
}
export function clearStashedDiagram() {
  window.localStorage.removeItem(STASHED_DIAGRAM_LOCAL_STORAGE_KEY);
}