import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './index.css';
import App from './App';
import EditDiagramPage from './routes/editDiagram/EditDiagramPage';
import ListDiagramsPage from './routes/ListDiagramsPage';
import ContactPage from './routes/ContactPage';
import ResetPasswordPage from './routes/ResetPasswordPage';
import VerifyEmailPage from './routes/VerifyEmailPage';
import AddPartPage from './routes/AddPartPage';
import reportWebVitals from './utils/reportWebVitals';
import ErrorHandlerContainer from './components/ErrorHandlerContainer';
import AuthContainer from './components/AuthContainer';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorHandlerContainer.Provider>
      <AuthContainer.Provider>
        <App>
          <Routes>
            <Route path="/" element={<EditDiagramPage />} />
            <Route path="/my-designs" element={<ListDiagramsPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/verify-email" element={<VerifyEmailPage />} />
            <Route path="/add-part" element={<AddPartPage />} />
          </Routes>
        </App>
      </AuthContainer.Provider>
      </ErrorHandlerContainer.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
