import React from 'react';
import { EdgeProps } from 'react-flow-renderer';
import { getSmoothStepPath } from 'react-flow-renderer';
import {FlowEdgeData} from '../types';

const posTruncDisplayDict = {
  right: {
    lineEndDX: 30,
    lineEndDY: 0,
    textDX: 0,
    textDY: 5,
    textAnchor: 'start',
  },
  bottom: {
    lineEndDX: 0,
    lineEndDY: 30,
    textDX: 0,
    textDY: 10,
    textAnchor: 'middle',
  },
  left: {
    lineEndDX: -30,
    lineEndDY: 0,
    textDX: 0,
    textDY: 5,
    textAnchor: 'end',
  },
  top: {
    lineEndDX: 0,
    lineEndDY: -30,
    textDX: 0,
    textDY: -5,
    textAnchor: 'middle',
  }
};

export default function CustomEdge(props: EdgeProps<FlowEdgeData>) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    sourceHandleId,
    targetHandleId,
    data
  } = props;
  
  const edgePath = getSmoothStepPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition});
  
  if (!data?.isTruncated) {
    return (
      <path id={id} className="react-flow__edge-path" d={edgePath} />
    );
  }
  
  const sourceTruncDisplay = posTruncDisplayDict[sourcePosition];
  const targetTruncDisplay = posTruncDisplayDict[targetPosition];
  
  const sourceDiagramID = data.sourceFlowNode.data?.diagramID;
  const targetDiagramID = data.targetFlowNode.data?.diagramID;
  const sourceHandleDef = data.sourceFlowNode.data?.partClassDef.handleDefs.find(x => x.portKey === sourceHandleId);
  const targetHandleDef = data.targetFlowNode.data?.partClassDef.handleDefs.find(x => x.portKey === targetHandleId);
  const sourceLabel = `${sourceDiagramID || ''}-${sourceHandleDef?.label || ''}`;
  const targetLabel = `${targetDiagramID || ''}-${targetHandleDef?.label || ''}`;
  
  return (
    <g id={id} className="react-flow__edge-path">
      <line
        x1={sourceX}
        y1={sourceY}
        x2={sourceX + sourceTruncDisplay.lineEndDX}
        y2={sourceY + sourceTruncDisplay.lineEndDY}
        stroke="inherit"
      />
      <line
        x1={targetX}
        y1={targetY}
        x2={targetX + targetTruncDisplay.lineEndDX}
        y2={targetY + targetTruncDisplay.lineEndDY}
        stroke = "inherit"
      />
      <text
        x={sourceX + sourceTruncDisplay.lineEndDX + sourceTruncDisplay.textDX}
        y={sourceY + sourceTruncDisplay.lineEndDY + sourceTruncDisplay.textDY}
        strokeWidth={1}
        stroke="black"
        fill="black"
        textAnchor={sourceTruncDisplay.textAnchor}
      >
        {targetLabel}
      </text>
      <text
        x={targetX + targetTruncDisplay.lineEndDX + targetTruncDisplay.textDX}
        y={targetY + targetTruncDisplay.lineEndDY + targetTruncDisplay.textDY}
        strokeWidth={1}
        stroke="black"
        fill="black"
        textAnchor={targetTruncDisplay.textAnchor}
        >
          {sourceLabel}
        </text>
    </g>
  );
}
