import React from 'react';
import * as ReactFlow from 'react-flow-renderer';
import {createPartClassPropDefs, PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as CableIcon} from './icons/Amp.svg';
import './Amplifier.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: 'In',  initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: 'Out', initPosition: ReactFlow.Position.Right}
];

const partClassDef: PartClassDef = {
  partClass: 'Amplifier',
  diagramIDPrefix: 'AR',
  handleDefs,
  propDefs: createPartClassPropDefs([
    {label: 'Manufacturer'},
    {label: 'Vendor Part Number'},
    {label: 'HM Part Number'},
    //{label: 'Description'},
    {label: 'Low Frequency (GHz)', format: 'number'},
    {label: 'High Frequency (GHz)', format: 'number'},
    {label: 'Gain (dB)', format: 'number'},
    {label: 'Noise Figure (dB)', format: 'number'},
    {label: 'P1dB (dBm)', format: 'number'},
    {label: 'Psat (dBm)', format: 'number'},
    {label: 'Connector Type'},
    //{label: 'Price', format: 'USD'},
    //{label: 'Lead Time (weeks)', format: 'number'},
    {label: 'Datasheet URL', format: 'URL'},
    //{label: 'Operating Temperature Range'},
  ]),
  FlowNodeComponent: ({data}) => {
    // const NFstr = data.partDict ? ', NF:'.concat(data.partDict['Noise Figure (dB)'] as string, 'dB') : '';  
    // const text = data.partDict ? 'G:'.concat(data.partDict['Gain (dB)'] as string,'dB',NFstr) : '';
    return (
      <div className={`flow-node amp-node ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <CableIcon/>
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        {/* <div className="label">{text}</div> */}
      </div>
    );
  }
};
export default partClassDef;