import config from '../config';
import * as HeroIcons from '@heroicons/react/outline';
import React, {useRef} from 'react';
import AuthContainer from '../components/AuthContainer';

const emailSubjectPrefix = 'RF Simulator - ';

export default function ContactPage() {
  const {authUser} = AuthContainer.useContainer();
  const commentRef = useRef<HTMLTextAreaElement>(null);
  
  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    const commentStr = commentRef.current?.value;
    const subjectStr = emailSubjectPrefix + (authUser? authUser.name : `Anon User`);
    window.open(
      `mailto:${config.contactEmail}?subject=${encodeURIComponent(subjectStr)}&body=${encodeURIComponent(commentStr || '')}`,
      '_blank'
    );
  }
  
  return (
    <div>
      <header>
        <h1 className="text-3xl font-bold leading-tight text-gray-900">Contact Us</h1>
      </header>
      <main className="pt-10">
        <form action="#" method="get" encType="text/plain" className="sm:max-w-lg" onSubmit={onSubmit}>
          <label htmlFor="comment" className="sr-only">
            Comment
          </label>
          <div>
            <textarea
              ref={commentRef}
              rows={5}
              name="comment"
              id="comment"
              className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md appearance-none border py-2 px-3 resize"
              placeholder="Add your comment..."
              defaultValue={''}
            />
          </div>
          <div className="mt-2">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              <HeroIcons.MailIcon className="w-5 h-5 mr-2 -ml-1" />
              Send
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}