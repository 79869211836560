import * as ReactFlow from 'react-flow-renderer';
import {PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {switchPropDefs} from '../switchPropDefs';
import {ReactComponent as SpdtIcon1} from './icons/SPDT_1.svg';
import {ReactComponent as SpdtIcon2} from './icons/SPDT_2.svg';
import './SPDT.css';

const stateDict: Record<string, {label: string; icon: typeof SpdtIcon1}> = {
  'conn0': {label: 'Output 1', icon: SpdtIcon1},
  'conn1': {label: 'Output 2', icon: SpdtIcon2},
};
const states = Object.entries(stateDict).map(([key, {label}]) => ({key, label}));

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: 'C', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: '1', initPosition: ReactFlow.Position.Right, offsetPercent: 25},
  {portKey: 'output1', label: '2', initPosition: ReactFlow.Position.Right, offsetPercent: 75},
];

const partClassDef: PartClassDef = {
  partClass: 'SPDT',
  diagramIDPrefix: 'SW',
  states,
  handleDefs,
  propDefs: switchPropDefs,
  FlowNodeComponent: ({data}) => {
    const stateKey = data.stateKey || states[0].key;
    const Icon = stateDict[stateKey].icon;
    //const text = data.partDict ? ''.concat('VSWR: ',data.partDict['Max VSWR'] as string) : data.label;
    return (
      <div className={`flow-node spdt-node state-${stateKey} ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <Icon />
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        {/* <div className="label">{text}</div> */}
      </div>
    );
  }
};
export default partClassDef;
