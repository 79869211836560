import React from 'react';
import * as ReactFlow from 'react-flow-renderer';
import {createPartClassPropDefs, PartClassDef, PartClassHandleDef} from '../partClassDef';
import {getRotClassName} from '../orientPosition';
import {FlowHandleSet} from '../FlowHandleSet';
import {ReactComponent as AttenIcon} from './icons/Attenuator.svg';
import './Attenuator.css';

const handleDefs: PartClassHandleDef[] = [
  {portKey: 'input0',  label: '1', initPosition: ReactFlow.Position.Left},
  {portKey: 'output0', label: '2', initPosition: ReactFlow.Position.Right}
];

const partClassDef: PartClassDef = {
  partClass: 'Attenuator',
  diagramIDPrefix: 'AT',
  handleDefs,
  propDefs: createPartClassPropDefs([
    {label: 'Manufacturer'},
    {label: 'Vendor Part Number'},
    {label: 'HM Part Number'},
    //{label: 'Description'},
    {label: 'Low Frequency (GHz)', format: 'number'},
    {label: 'High Frequency (GHz)', format: 'number'},
    {label: 'Connector Type'},
    {label: 'Adapter Type'},
    {label: 'Max VSWR'},
    {label: 'Attenuation Value (dB)', format: 'number'},
    {label: 'Average Power (W)', format: 'number'},
    {label: 'Peak Power (W)', format: 'number'},
    //{label: 'Price', format: 'USD'},
    //{label: 'Lead Time (weeks)', format: 'number'},
    {label: 'Datasheet URL', format: 'URL'},
    //{label: 'Operating Temperature Range'},
  ]),
  FlowNodeComponent: ({data}) => {
    const atr = data.part?.propDict['Attenuation Value (dB)'];
    const text = atr !== undefined? String(atr) + 'dB' : '';
    return (
      <div className={`flow-node attenuator-node ${getRotClassName(data.orientation)} ${data.part ? 'haspart':'nopart'}`}>
        <AttenIcon/>
        <FlowHandleSet handleDefs={handleDefs} orientation={data.orientation} />
        <div className="diagram-id">{data.diagramID}</div>
        <div className="label">{text}</div>
      </div>
    );
  }
};
export default partClassDef;